<template>
  <BaseScreen name="embedForm" class="embedForm">
    <div id="typeform" class="embedForm__inner"></div>
  </BaseScreen>
</template>

<script>
import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";
// import axios from 'axios'
// import { mapState } from 'vuex'
export default {
  name: "EmbedForm",
  props: {
    outcomePlacement: String,
    outcomeKey: String,
  },
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    session() {
      const session = this.$store.state.session;
      return session;
    },
    tgmSession() {
      return this.$store.state.tgmSession;
    },
  },
  watch: {
    session(state) {
      if (state.length > 0) {
        this.mountWidget();
      }
    },
  },
  methods: {
    mountWidget() {
      if (this.tgmSession) {
        this.$log.event({
          event_type: "start_tgm_survey",
          target: this.tgmSession,
        });
      } else {
        this.$log.event({
          event_type: "start_tgm_survey",
          target: "missing",
        });
      }
      createWidget(this.outcomeKey, {
        container: document.querySelector("#typeform"),
        hidden: {
          sessionid: this.session,
          tgmsession: this.tgmSession,
        },
        hideHeaders: true,
        hideFooter: true,
        onSubmit: async () => {
          await this.$log.event({
            event_type: "submitted_survey",
            target: "TGM",
          });
          setTimeout(() => {
            if (this.outcomePlacement === "start") {
              this.$emit("next");
            } else {
              window.location.href = `https://tgm.mobi/status/fin?id=${this.tgmSession}`;
            }
          }, 1000);
        },
      });
      this.mounted = true;
    },
  },
};
</script>

<style>
.embedForm__inner {
  width: 80vw;
  height: 80vh;
}
</style>
