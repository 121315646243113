<template>
  <div class="app__toolbar-wrapper" role="banner">
    <div class="app__toolbar above">
      <TheSoundButton />

      <BylineButton v-if="byline && !showUuid" :text="byline" />
    </div>
    <div class="app__toolbar below" v-if="!inGame">
      <LanguagePicker />

      <BaseButton
        v-if="privacyPolicyUrl && !showUuid"
        :href="privacyPolicyUrl"
        new-tab
        is-quaternary
        >{{ $l10n("privacy-policy") }}</BaseButton
      >

      <BaseButton
        v-if="termsOfUseUrl && !showUuid"
        :href="termsOfUseUrl"
        new-tab
        is-quaternary
        >{{ $l10n("terms-of-use") }}</BaseButton
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inGame: Boolean,
    byline: String,
    privacyPolicyUrl: String,
    termsOfUseUrl: String,
    showUuid: Boolean,
  },
};
</script>
