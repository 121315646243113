<template>
	<div :class="{
			'draggable-hand': true,
			[ this.type ]: true,
			[ `is-color-${ color }` ]: true,
			'animating': isAnimating,
			'dragging': isDragging,
			'in-range': isInRange,
		}">
		<BaseButton
			v-if="withHolster"
			is-plain
			class="draggable-hand__holster"
			@click="select"
		>
			<div class="draggable-hand__holster__label">{{ label }}</div>
		</BaseButton>
		<div class="draggable-hand__handle" ref="handle">
			<BaseButton
				v-if="! withHolster"
				is-plain
				class="draggable-hand__handle__label"
				@click="select"
			>{{ label }}</BaseButton>
			<HandGraphic :color="color" :is-open="isInRange" />
		</div>
		<svg class="draggable-hand__arm" ref="arm">
			<path class="draggable-hand__arm__stroke" :d="armPath" />
			<path class="draggable-hand__arm__fill" :d="armPath" />
		</svg>
	</div>
</template>

<script>
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';

gsap.registerPlugin( Draggable );

import checkIntersect from '../utilities/check-intersect';

// Half-assed check for mobile safari
const ua = navigator.userAgent.toLowerCase();
const needSafariHack = ua.indexOf( 'safari' ) >= 0 && ua.indexOf( 'chrome' ) < 0;

const ANCHOR_BEZIER_WEIGHT = 0;
const HANDLE_BEZIER_WEIGHT = 0;

const ANCHOR_BEZIER_OFFSET = 0;
const HANDLE_BEZIER_OFFSET = 150;

export default {
	inheritAttrs: false,
	props: {
		type: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		color: {
			type: String,
		},
		target: HTMLElement,
		enabled: {
			type: Boolean,
			default: true,
		},
		margin: Number,
		withHolster: Boolean,
	},
	data() {
		return {
			draggable: null,
			isAnimating: false,
			isDragging: false,
			isInRange: false,

			anchorX: 0,
			anchorY: 0,
			handleX: 0,
			handleY: 0,

			alignment: [ 'left', 'top-left', 'bottom-left' ].includes( this.type ) ? 'left' : 'right',
		};
	},
	computed: {
		armPath() {
			const x1 = this.anchorX;
			const y1 = this.anchorY;
			const x4 = this.handleX;
			const y4 = this.handleY;

			let x2 = x1;
			let y2 = y1;
			let x3 = x4;
			let y3 = y4;

			let dx1 = Math.abs( x4 - x1 ) * ANCHOR_BEZIER_WEIGHT;
			let dx2 = Math.abs( x4 - x1 ) * HANDLE_BEZIER_WEIGHT;

			dx1 += ANCHOR_BEZIER_OFFSET;
			dx2 += HANDLE_BEZIER_OFFSET;

			// Flip dx values if aligned left
			if ( this.alignment === 'left' ) {
				dx1 *= -1;
				dx2 *= -1;
			}

			x2 -= dx1;
			x3 += dx2;

			const data = `M${ x1 } ${ y1 } C ${ x2 } ${ y2 } ${ x3 } ${ y3 } ${ x4 } ${ y4 }`;

			return data;
		},
	},
	watch: {
		enabled( isEnabled ) {
			if ( this.draggable ) {
				if ( isEnabled ) {
					this.draggable.enable();
				} else {
					this.draggable.disable();
				}
			}

			this.calculateAnchors();
		},
	},
	methods: {
		calculateAnchors() {
			const handle = this.$refs.handle.getBoundingClientRect();
			const canvas = document.body.getBoundingClientRect();

			let x, y;
			switch ( this.type ) {
				case 'left':
					x = -235;
					y = 60;
					break;

				case 'right':
					x = 235;
					y = 30;
					break;

				case 'top-left':
					x = -235;
					y = 60;
					break;

				case 'top-right':
					x = 220;
					y = 60;
					break;

				case 'bottom-left':
					x = -100;
					y = 60;
					break;

				case 'bottom-right':
					x = 100;
					y = 60;
					break;
			}

			this.anchorX = ( canvas.width / 2 ) + x;
			this.anchorY = canvas.height + y;

			this.handleX = handle.left + ( handle.width / 2 );
			this.handleY = handle.top + ( handle.height / 2 );
		},
		checkIntersect() {
			if ( this.target ) {
				this.isInRange = checkIntersect( this.$refs.handle, this.target, this.margin );
			}
		},
		reset() {
			if ( ! this.draggable ) {
				return;
			}

			this.isAnimating = true;
			this.draggable.disable();
			gsap.to( this.$refs.handle, {
				x: 0,
				y: 0,
				ease: 'power2.out',
				duration: .4,
				onUpdate: () => {
					this.calculateAnchors();

					// Constantly change the left px of the arm canvas,
					// in order to force a proper repaint sans-artifacts
					if ( needSafariHack ) {
						this.$refs.arm.style.left = Math.random() + 'px';
					}
				},
				onComplete: () => {
					this.calculateAnchors();
					this.isAnimating = false;

					this.draggable.update();
					if ( this.enabled ) {
						this.draggable.enable();
					}
				},
			} );
		},
		select( event ) {
			// We only want keyboard "clicks"
			if ( event.detail ) {
				return;
			}

			this.isInRange = true;
			this.$emit( 'drop', true, this );
		},
	},
	mounted() {
		const handle = this.$refs.handle;

		const [ drag ] = Draggable.create( handle, {
			zIndexBoost: false,
			onPress: () => {
				this.isDragging = true;
				this.$emit( 'update', this.isDragging, this );
			},
			onRelease: () => {
				this.isDragging = false;
				this.$emit( 'update', this.isDragging, this );
			},
			onDragStart: () => {
				this.$sounds.play( 'pickup' );
			},
			onDrag: () => {
				this.checkIntersect();
				this.calculateAnchors();
				this.$emit( 'drag', this.isInRange, this );

				// Constantly change the left px of the arm canvas,
				// in order to force a proper repaint sans-artifacts
				if ( needSafariHack ) {
					this.$refs.arm.style.left = Math.random() + 'px';
				}
			},
			onDragEnd: () => {
				this.checkIntersect();
				this.$emit( 'drop', this.isInRange, this );
			},
		} );

		this.draggable = drag;
		this.calculateAnchors();

		this.$nextTick( () => {
			window.addEventListener( 'resize', this.calculateAnchors );
		} );
	},
}
</script>
