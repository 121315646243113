<template functional>
	<div :class="{
		'hand': true,
		[ 'is-color-' + props.color ]: true,
		'is-open': props.isOpen,
	}">
		<div class="hand--closed" />
		<div class="hand--open" />
	</div>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			required: true,
		},
		isOpen: Boolean,
	},
}
</script>
