<template>
	<BaseDraggable
		:is-active="isActive"
		:hands="hands"
		@select="handleResult"
	/>
</template>

<script>
const TYPES = [ 'top-left', 'top-right', 'bottom-left', 'bottom-right' ];

export default {
	inheritAttrs: false,
	props: {
		isActive: {
			type: Boolean,
			required: true,
		},
		values: {
			type: Array,
			required: true,
		},
		labels: {
			type: Array,
			required: true,
		},
		colors: {
			type: Array,
			required: true,
		},
	},
	data() {
		const hands = [];
		const optionCount = Math.min( this.values.length, this.labels.length, TYPES.length );

		for ( let i = 0; i < optionCount; i++ ) {
			hands.push( {
				type: TYPES[ i ],
				color: this.colors[ i ],
				value: this.values[ i ],
				label: this.labels[ i ],
			} );
		}

		return {
			hands,
		};
	},
	methods: {
		handleResult( result ) {
			this.$emit( 'answer', result );
		},
	},
}
</script>
