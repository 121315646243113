<template functional>
	<span :class="`icon icon--${ props.type }`" />
</template>

<script>
export default {
	props: {
		type: String,
	},
}
</script>
