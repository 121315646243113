import Vue from "vue";
import { forOwn } from "lodash";
import { mapMutations } from "vuex";
import PortalVue from "portal-vue";
import { Howler } from "howler";

import {
  name as surveyName,
  config,
  translations,
  main_questions,
  followup_interfaces,
  recovery_questions,
  interrupt_questions,
  demographic_survey,
  levels,
  languages,
} from "./config.js";

import store from "./utilities/store.js";
import fixText from "./utilities/text.js";
import l10n from "./utilities/l10n.js";
import baseUrl from "./utilities/url.js";
import logging from "./utilities/logging.js";
import sounds from "./utilities/sounds.js";

const BASE_URL = process.env.VUE_APP_BASE_URL || process.env.BASE_URL;
// const LOGGING_URL = process.env.VUE_APP_LOGGING_URL;

Vue.config.productionTip = false;

Vue.use(PortalVue);
Vue.use(fixText);
Vue.use(l10n, translations);
Vue.use(baseUrl, BASE_URL);
Vue.use(logging, surveyName, store);

Vue.mixin({
  methods: mapMutations(["showModal", "hideModal"]),
});

store.state.baseURL = BASE_URL;
store.state.languages = languages;

Howler.volume(store.state.useAudio ? 1 : 0);

// If a subdirectory was specified (usually permalinking to a reward),
// replace it with the root URL, preserve the GET params though
let request = window.location.pathname.substring(BASE_URL.length);
if (window.location.search) {
  request += "?" + window.location.search;
}
history.replaceState(
  {
    request,
  },
  document.title,
  BASE_URL
);

// Handle auto-muting when focus leaves/returns
var hiddenProp, visibilityChange;
if (typeof document.hidden !== "undefined") {
  // Opera 12.10 and Firefox 18 and later support
  hiddenProp = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hiddenProp = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hiddenProp = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

document.addEventListener(visibilityChange, function () {
  if (document[hiddenProp]) {
    Howler.volume(0);
  } else {
    if (store.state.useAudio) {
      Howler.volume(1);
    }
  }
});

export default function init({ soundEffects, ...appConfig }, components) {
  // Globally register all provided components

  forOwn(components, (component, name) => {
    Vue.component(name, component);
  });

  if (soundEffects) {
    Vue.use(sounds, soundEffects.source, soundEffects.sprite);
  }

  new Vue({
    store,
    el: "#app",
    render: (h) =>
      h("App", {
        props: {
          ...config,
          main_questions,
          followup_interfaces,
          recovery_questions,
          interrupt_questions,
          demographic_survey,
          levels: levels.map((level, i) => ({ index: i, ...level })),
          languages,
          ...appConfig,
        },
      }),
  });
}
