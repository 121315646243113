<template>
	<div class="gamestatus">
		<BaseButton
			name="avatar"
			location="main"
			:class="[ 'gamestatus__avatar', 'is-level-' + ( level.index + 1 ) ]"
			isPlain
			@click="showModal( 'levels' )"
		>
			<div class="gamestatus__avatar__image">
				<img :src="level.graphic" />
			</div>
			<ScreenReaderText>{{ $l10n( level.name ) }}</ScreenReaderText>
		</BaseButton>

		<StarGraphic
			:number="level.index + 1"
			:class="{
				'gamestatus__level': true,
				'is-change-up': isChangeUp,
				'is-change-down': isChangeDown,
			}"
		/>

		<div v-if="showStars" class="gamestatus__stars">
			<ProgressStarIcon
				v-for="n in totalStars"
				:key="level.index + '-' + n"
				:filled="n <= filledStars"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		level: {
			type: Object,
			required: true,
		},
		totalStars: {
			type: Number,
			required: true,
		},
		filledStars: {
			type: Number,
			required: true,
		},
		showStars: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			isChangeUp: false,
			isChangeDown: false,
		};
	},
	watch: {
		level( newLevel, oldLevel ) {
			this.isChangeUp = newLevel.index > oldLevel.index;
			this.isChangeDown = newLevel.index < oldLevel.index;
		},
	},
	methods: {
		doneAnimating() {
			this.isChangeUp = this.isChangeDown = false;
		},
	},
}
</script>
