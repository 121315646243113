<template>
	<div :class="{
		'slider-question': true,
		'with-hands': useHands,
	}">
		<div class="slider-question__help">
			<BaseIcon type="left" />
			<span class="slider-question__help__text" v-content="$l10n( 'slider-help' )" />
			<BaseIcon type="right" />
		</div>

		<div class="slider-question__labels">
			<span class="slider-question__label">{{ leftLabel }}</span>
			<span class="slider-question__label">{{ rightLabel }}</span>
		</div>

		<div class="slider-question__bar" ref="bar">
			<input
				v-for="n in ( steps * 2 + 1 )"
				:key="n"
				type="radio"
				class="slider-question__marker"
				:name="`v${ _uid }`"
				@focus="setValue( n - steps - 1 )"
				@change="setValue( n - steps - 1 )"
			/>
			<div class="slider-question__thumb" ref="thumb">
				<div v-if="emoteGraphic ==='NonEmotionalGraphic'">
					<svg :class="[ 'emote', { smallerCircleOnMobile: (steps * 2 + 1) > 8} ]" viewBox="0 0 76 76">
						<circle fill="transparent" cx="38" cy="38" r="38" class="emote__backfill" style="touch-action: pan-y;"/>
						<circle class="emote__fill" fill="#E5D7CF" stroke="#4E1F7D" stroke-width="3" cx="38" cy="38" r="32"/>
					</svg>
				</div>
				<Component v-else :is="emoteGraphic" :face="emote" />
			</div>
		</div>

		<BaseButton
			icon="next"
			is-primary
			is-circular
			class="slider-question__submit"
			@click="$emit( 'answer', value )"
		>{{ $l10n( 'submit' ) }}</BaseButton>
	</div>
</template>

<script>
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';

const NON_EMOTIONAL_QUESTIONS = ['Political affiliation']

gsap.registerPlugin( Draggable );

const EMOTES = [
	'very-upset',
	'upset',
	'neutral',
	'happy',
	'very-happy',
];

export default {
	inheritAttrs: false,
	props: {
		steps: {
			type: Number,
			default: 2,
		},
		labels: {
			type: Array,
			required: true,
			validator: array => array.length >= 2,
		},
		useHands: Boolean,
	},
	data() {
		const [ leftLabel, rightLabel ] = this.labels;
		let emoteGraphic = this.useHands ? 'ThumbEmoteGraphic' : 'EmoteGraphic';
		if (NON_EMOTIONAL_QUESTIONS.includes(this.$attrs.name)) {
			emoteGraphic = 'NonEmotionalGraphic'
		}
		return {
			value: 0,
			leftLabel,
			rightLabel,
			emoteGraphic,
		};
	},
	computed: {
		emote() {
			return EMOTES[ this.value + this.steps ];
		},
	},
	methods: {
		calculateSnap( x ) {
			const { bar, thumb } = this.$refs;

			const barBounds = bar.getBoundingClientRect();
			const thumbBounds = thumb.getBoundingClientRect();

			const range = ( barBounds.width - thumbBounds.width ) / 2;
			const stopSize = range / this.steps;

			this.value = Math.round( x / stopSize );

			return this.value * stopSize;
		},
		setValue( value ) {
			const { bar, thumb } = this.$refs;

			const barBounds = bar.getBoundingClientRect();
			const thumbBounds = thumb.getBoundingClientRect();

			const range = ( barBounds.width - thumbBounds.width ) / 2;
			const stopSize = range / this.steps;

			this.value = value;

			this.draggable.disable();
			gsap.to( thumb, {
				x: value * stopSize,
				ease: 'none',
				duration: .2,
				onComplete: () => {
					this.draggable.enable().update();
				}
			} );
		},
	},
	mounted() {
		const slider = this;
		const { bar, thumb } = this.$refs;

		const [ drag ] = Draggable.create( thumb, {
			zIndexBoost: false,
			bounds: bar,
			type: 'x',
			onDrag() {
				slider.calculateSnap( this.endX );
			},
			onDragEnd() {
				const snapX = slider.calculateSnap( this.endX );

				this.disable();
				gsap.to( thumb, {
					x: snapX,
					ease: 'none',
					duration: .2,
					onComplete: () => {
						this.enable().update();
					}
				} );
			},
		} );

		this.draggable = drag;
	},
}
</script>
