<template>
  <BaseScreen
    name="end"
    :class="['endscreen', 'is-level-' + (level.index + 1)]"
  >
    <div class="endscreen__header">
      <StarGraphic :number="level.index + 1" class="endscreen__star" />

      <h2>{{ $l10n("finish-rank", $l10n(level.index + 1)) }}</h2>
      <p v-if="level.message">{{ level.message }}</p>

      <div v-if="showUUID && session && outcomePlacement === 'end'">
        <BaseButton is-primary @click="$emit('next', 'end')">{{
          $l10n("your-uuid")
        }}</BaseButton>
      </div>

      <BaseButton
        v-if="!showUUID"
        name="share"
        location="end"
        is-primary
        @click="showModal('share')"
        >{{ $l10n("share-level") }}</BaseButton
      >

      <BaseButton
        v-if="!showUUID"
        name="restart"
        location="end"
        is-plain
        @click="$emit('restart')"
        >{{ $l10n("restart") }}</BaseButton
      >
    </div>

    <div
      class="endscreen__graphic"
      :style="{
        'background-image': `url(${$url(level.graphic)})`,
      }"
    />
  </BaseScreen>
</template>

<script>
export default {
  inject: ["showUUID"],
  props: {
    level: {
      type: Object,
      required: true,
    },
    outcomePlacement: String,
  },
  computed: {
    currentScreen() {
      return this.$store.state.currentScreen;
    },
    tgmSession() {
      return this.$store.state.tgmSession;
    },
    session() {
      const session = this.$store.state.session;

      if (this.showUUID === true) {
        return session;
      } else if (this.showUUID) {
        return session.substr(-1 * this.showUUID);
      }

      return false;
    },
  },
  watch: {
    currentScreen(val) {
      if (val === "end" && this.session) {
        if (this.showUUID && this.outcomePlacement === "start") {
          setTimeout(() => {
            window.location.href = `https://tgm.mobi/status/fin?id=${this.tgmSession}`;
          }, 1000);
        }
      }
    },
  },
};
</script>
