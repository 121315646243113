<template>
  <BaseScreen name="home" class="homescreen">
    <div class="homescreen__hand homescreen__hand--thumbup"></div>
    <div class="homescreen__hand homescreen__hand--thumbdown"></div>
    <div class="homescreen__header">
      <h1 class="homescreen__title" v-content="title" />
      <p v-if="tagline" class="homescreen__tagline" v-content="tagline" />
    </div>
    <div class="homescreen__body">
      <BaseButton
        name="start"
        location="home"
        is-primary
        is-large
        @click="$emit('next')"
        >{{ buttonText || $l10n("start") }}</BaseButton
      >
    </div>
  </BaseScreen>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    tagline: String,
    buttonText: String,
  },
};
</script>
