<template>
  <BaseButton
    :name="useAudio ? 'mute' : 'unmute'"
    location="main"
    :class="{
      soundtoggle: true,
      'is-muted': !useAudio
    }"
    is-tertiary
    @click="toggleAudio"
  >
    <BaseIcon v-show="useAudio" type="soundOn" class="soundtoggle__icon" />
    <BaseIcon v-show="!useAudio" type="soundOff" class="soundtoggle__icon" />

    <BaseIcon v-show="useAudio" type="waves" class="soundtoggle__waves" />

    <ScreenReaderText v-if="useAudio">{{ $l10n("mute") }}</ScreenReaderText>
    <ScreenReaderText v-else>{{ $l10n("unmute") }}</ScreenReaderText>
  </BaseButton>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState(["useAudio"])
  },
  methods: mapMutations(["toggleAudio"])
};
</script>
