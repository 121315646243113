<template>
	<svg
		:class="{
			'timer-shape': true,
			'is-animating': animate,
		}"
		:viewBox="`0 0 ${ width } ${ height }`"
		:width="width"
		:height="height"
	>
		<mask class="timer-shape__mask" :id="uid">
			<circle r="90" cx="100" cy="100" />
		</mask>
		<g class="timer-shape__outline">
			<circle r="98" cx="100" cy="100" />
		</g>
		<g :mask="`url(#${ uid })`">
			<TimerShapeFill
				v-if="duration"
				class="timer-shape__timer"
				fill="timerFill"
				:fill-opacity="timerOpacity"
				:width="width"
				:height="height"
				:duration="duration * 1000"
				:start="start"
				:paused="paused"
				:delay="delay * 1000"
				@done="$emit( 'expire' )"
			/>
			<rect v-else x="0" y="0" :width="width" :height="height" :fill="altFill" :fill-opacity="altOpacity" />
		</g>
	</svg>
</template>

<script>
export default {
	props: {
		duration: Number,
		delay: Number,
		start: Boolean,
		paused: Boolean,
		animate: Boolean,
	},
	data() {
		return {
			uid: 'v' + Date.now(),
			width: 200,
			height: 200,
			timerFill: 'black',
			timerOpacity: 0.05,
			altFill: 'none',
			altOpacity: 0,
		};
	},
}
</script>
