<template functional>
	<svg :class="{
		'hand': true,
		[ 'is-color-' + props.color ]: true,
		'is-open': props.isOpen,
	}" viewBox="0 0 156 194">
		<g class="hand--open">
			<path class="hand__fill" d="M146.3,150.6c1.9,2.1,2.8,5,2.4,7.8c-0.3,2.5-1.6,4.7-3.5,6.3c1.9,3.1,2.2,6.9,0.9,10.3
				c-2.6,6.5-9.3,11-15.7,13.3c-6.3,2.3-13.2,2.2-19.4-0.2c-0.2,0.1-0.3,0.2-0.5,0.3c-6.6,2.8-13.7,4.5-20.8,5.2
				c-23.8,2.3-47.4-6.2-64.2-23.2l0,0c-6.3-2.2-12-6-16.3-11.2c-3.9-5-6.4-10.8-7.5-17c-1.6-8.7-0.7-17.9,1.5-26.4
				c3.2-12.3,9.5-23.6,18.4-32.7c2.3-2.3,4.8-4.5,7.4-6.5c1.4-1.1,2.9-2.1,4.4-3.1c1.3-0.7,2.5-1.5,3.7-2.4c4-3.2,5.7-8.7,7.7-13.3
				c2.7-6.4,6-12.6,8.9-18.9c3.2-6.8,6.5-13.6,9.7-20.4C66.1,12.9,68.6,6.9,74,3.3c4.6-3.1,11-4.6,16-1.7c4.6,2.7,7.4,8.2,8,13.4
				c0.4,3.4-0.4,6.8-1.1,10.1c-0.7,3.6-1.4,7.2-2.1,10.8c-2.5,12.7-4.8,25.5-6.9,38.3c7,0.2,14,1.1,20.8,2.7c6.7,1.6,13.1,4,19.3,6.9
				c5.7,2.6,11.1,5.9,16,9.8c2.2,1.7,4,3.8,5.3,6.3c1.1,2.6,1.5,5.5,1.1,8.4c-0.2,4.9-2.2,9.5-5.6,13c5.5,1.7,9.4,6.4,10.2,12
				C155.9,140.4,151.5,146.4,146.3,150.6z"/>
			<g class="hand__outlines">
				<path class="hand__outline" d="M155,133.4c-0.8-5.7-4.8-10.3-10.2-12c3.4-3.5,5.4-8.1,5.6-13c0.4-2.8,0-5.7-1.1-8.4
					c-1.3-2.5-3.1-4.6-5.3-6.3c-4.9-3.9-10.3-7.2-16-9.8c-6.2-3-12.7-5.3-19.3-6.9c-6.8-1.7-13.8-2.6-20.8-2.7
					c2.1-12.8,4.4-25.6,6.9-38.3c0.7-3.6,1.4-7.2,2.1-10.8c0.7-3.3,1.5-6.7,1.1-10.1c-0.6-5.2-3.3-10.7-8-13.4c-5-2.9-11.4-1.4-16,1.7
					c-5.4,3.6-7.9,9.7-10.6,15.3c-3.3,6.8-6.5,13.6-9.7,20.4c-3,6.3-6.2,12.5-8.9,18.9c-2,4.6-3.7,10.1-7.7,13.3
					c-1.2,0.9-2.4,1.6-3.7,2.4c-1.5,1-3,2-4.4,3.1c-2.6,2-5.1,4.1-7.4,6.5c-8.9,9.1-15.2,20.4-18.4,32.7c-2.2,8.5-3.1,17.7-1.5,26.4
					c1.1,6.2,3.6,12,7.5,17c4.3,5.2,9.9,9,16.3,11.2l0,0c16.9,16.9,40.4,25.4,64.2,23.2c7.2-0.7,14.2-2.4,20.8-5.2
					c0.2-0.1,0.4-0.2,0.5-0.3c6.2,2.4,13.1,2.5,19.4,0.2c6.4-2.2,13.1-6.7,15.7-13.3c1.4-3.4,1.1-7.2-0.9-10.3
					c1.9-1.6,3.1-3.9,3.5-6.3c0.3-2.8-0.5-5.7-2.4-7.8C151.5,146.4,155.9,140.4,155,133.4z M42,180c-3.8-2.3-7.3-4.9-10.6-7.9
					c1.7,0.4,3.4,0.7,5.1,0.9c0.7,0.2,1.5-0.2,1.7-1c0.2-0.7-0.2-1.5-1-1.7c-8.2-1.1-16.5-3.8-22.7-9.5c-4.4-4.2-7.6-9.5-9.2-15.4
					c-1.3-4.8-1.8-9.7-1.6-14.6c0.1-1.6,0.2-3.2,0.4-4.8c0-0.1,0.1-0.5,0.1-0.6c0-0.2,0.1-0.5,0.1-0.7c0-0.2,0.1-0.4,0.1-0.6
					c0.8-5.3,2.2-10.6,4.2-15.6c2.2-5.6,5.2-11,8.7-15.9c3.7-5.1,8.1-9.6,13.1-13.4c2.5-1.9,5.1-3.3,7.6-5.1c2.1-1.5,3.8-3.5,5-5.7
					c1.2-2.2,2.3-4.5,3.3-6.9c1.1-2.6,2.2-5.1,3.4-7.6c3.1-6.5,6.3-13.1,9.3-19.6c3.1-6.5,6.1-13,9.3-19.4c1.2-2.7,2.9-5.2,4.9-7.4
					c1.9-1.9,4.3-3.2,6.9-4c2.6-0.9,5.3-0.8,7.8,0.3c2.2,1.2,4,3.1,5.1,5.3c1.3,2.2,2,4.8,2.1,7.3c0.1,3.6-0.9,7.2-1.6,10.7
					c-2.5,12.7-4.9,25.5-7.1,38.2c-0.5,3.1-1,6.2-1.5,9.3c-0.6,0.5-0.6,1.5-0.1,2c0.2,0.2,0.5,0.4,0.8,0.4c0.4,0.2,0.9,0.2,1.3,0
					c12.5,0.2,24.8,2.8,36.3,7.8c5.6,2.5,11,5.4,16,8.9c4.2,2.9,8.4,6.5,8.4,11.9c0.1,2.9-0.3,5.8-1.3,8.5c-1.1,2.6-2.8,5-5.1,6.7
					c-0.4,0.3-0.6,0.9-0.4,1.3c-14,8.7-31.7,11.8-47.7,7.6c-7.6-2-16.2-7.4-15.6-16.4c0.3-3.9,2.9-7,6.2-8.9c3.8-2.1,8.1-2.7,12.3-2.7
					c8.8-0.2,19.6,1.2,26-6.4c0.8-0.9-0.5-2.3-1.3-1.3c-6.2,7.4-17,5.6-25.6,5.9c-7.6,0.2-17.3,3-19.2,11.5c-0.8,4,0.2,8.1,2.6,11.4
					c1.8,2.5,4.3,4.6,7.1,6c-3.6,2.3-5.1,7.1-4.1,11.3c1.1,4.5,4.7,7.8,8.7,9.9c-3,3-3.8,7.5-2.1,11.4c1.9,4.2,6,7.1,10,9
					c0.2,0.1,0.4,0.2,0.6,0.3c-2.4,2.7-1.8,7,0.6,9.8c1.5,1.7,3.3,3.1,5.3,4.2c1.1,0.7,2.3,1.3,3.5,1.8C86.8,194.7,61.6,191.9,42,180
					L42,180z M137.8,181.2c-4.7,3.7-10.5,5.8-16.5,6c-3.3,0.1-6.6-0.5-9.6-1.9c-0.3-0.1-0.6-0.2-0.9-0.1c-3.5-1.1-6.7-3-9.3-5.5
					c-1.2-1.1-2-2.6-2.3-4.2c-0.3-1.6,0.3-3.2,1.6-4.2c8.3,2.9,17.4,2.2,25.8-0.2c5.3-1.6,10.5-3.6,15.5-5.8c0.1,0.2,0.3,0.3,0.5,0.4
					C147,171,142.1,177.8,137.8,181.2L137.8,181.2z M146,157.8c-0.3,1.8-1.1,4-2.7,5c-0.1,0-0.2,0-0.2,0.1c-8.9,3.8-18.1,7.9-27.8,8.5
					c-4.8,0.3-9.7-0.3-14.2-1.9c-0.1-0.1-0.2-0.1-0.3-0.1c-3.4-1.2-6.4-3.2-8.9-5.9c-1.7-1.7-2.6-4-2.7-6.5c0.1-2.4,1.1-4.6,2.9-6.2
					c0,0,0,0,0.1-0.1c5.4,2.2,11.6,3,17.4,3.6c6.1,0.7,12.2,0.8,18.3,0.3c5.2-0.5,10.3-1.6,15.3-3.3c0.1,0.1,0.1,0.2,0.2,0.2
					C145,153.1,146.4,155.5,146,157.8L146,157.8z M143.7,149.2C143.7,149.2,143.7,149.2,143.7,149.2c-9.4,3.5-19.6,4.4-29.6,3.7
					c-5.5-0.3-10.9-1.1-16.3-2.3c-4.9-1.2-10.1-3.3-13-7.7c-2.7-4.2-2.4-10.4,2.5-12.9c0.1-0.1,0.2-0.1,0.3-0.2
					c0.3,0.1,0.5,0.2,0.8,0.4c8.2,3.3,17.9,3.9,26.6,2.9c9.6-1.1,18.8-4.3,27-9.3c0.1,0,0.2,0.1,0.3,0.1c5.1,0.8,9.1,4.9,9.9,10
					C153,140.1,148.4,145.5,143.7,149.2L143.7,149.2z"/>
				<path class="hand__outline" d="M64.7,103c-1.9,13.7-4.3,29.5-16.4,38.2c-1,0.7,0,2.3,0.9,1.6c12.5-9.1,15.2-25,17.2-39.3
					C66.7,102.3,64.9,101.8,64.7,103z"/>
				<path class="hand__outline" d="M85.7,45.2c0.5-0.1,0.8-0.7,0.7-1.2c-0.1-0.5-0.7-0.8-1.2-0.7c-3.9,0.6-7.9,0.2-11.7-0.9
					c-1.2-0.4-1.7,1.5-0.5,1.8C77.1,45.5,81.5,45.8,85.7,45.2z"/>
				<path class="hand__outline" d="M82.1,47.4l-4.9-0.6c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9l4.9,0.6
					c0.5,0,0.9-0.4,0.9-0.9C83.1,47.8,82.7,47.4,82.1,47.4z"/>
			</g>
		</g>
		<g class="hand--closed">
			<path class="hand__fill" d="M146.4,150.6c1.9,2.1,2.8,5,2.4,7.8c-0.3,2.5-1.6,4.7-3.4,6.3c1.9,3.1,2.2,6.9,0.8,10.3
				c-2.6,6.5-9.3,11-15.7,13.3c-6.3,2.3-13.2,2.2-19.4-0.2c-0.2,0.1-0.3,0.2-0.5,0.3c-6.6,2.8-13.7,4.5-20.8,5.2
				c-23.8,2.3-47.4-6.2-64.2-23.2c0,0,0,0,0,0c-6.7-2.3-12.5-6.4-16.9-12c-3.8-5.1-6.2-11.2-7.1-17.5c-0.7-4.7-0.8-9.4-0.3-14.1
				c0.6-5.6,1.8-11.1,3.6-16.4C9.2,98,16.7,86.5,27,78.1c4.8-3.7,10-6.9,15.5-9.5c5.3-2.7,10.7-4.9,16.3-6.8
				c6.6-2.1,13.4-2.1,20.2-1.6c7.7,0.6,15.4,1,23.1,1.4c3.8,0.2,7.7,0.4,11.5,0.5c3.3,0.1,6.6,0.5,9.8,1.4c5.9,1.6,11.9,5.3,14.6,11
				c2.2,4.8,2,10.4-0.7,15c0.4-0.2,0.9-0.2,1.3,0.1c5.5,3.6,11.7,8,12.1,15.1c0.3,6.2-1.4,12.2-5.7,16.5c5.5,1.7,9.5,6.4,10.2,12
				C155.9,140.4,151.5,146.4,146.4,150.6z"/>
			<g class="hand__outlines">
				<path class="hand__outline" d="M144.8,121.4c-0.3,0.3-0.7,0.7-1.1,1c-0.1,0.1-0.3,0.3-0.4,0.4c-0.6,0.5-1.4,0.5-1.9,0
					c-0.2-0.2-0.3-0.4-0.4-0.6c-0.2-0.5-0.1-1.1,0.4-1.4c2.3-1.8,4.1-4.2,5.2-7c0.9-2.8,1.3-5.7,1.2-8.6c-0.2-6.2-6-10-10.7-13
					c-0.5-0.3-0.7-0.8-0.6-1.4c0.1-0.5,0.3-0.9,0.8-1.2c0.4-0.2,0.9-0.2,1.3,0.1c5.5,3.6,11.7,8,12.1,15.1
					C150.8,111,149.2,117,144.8,121.4z"/>
				<path class="hand__outline" d="M146.4,150.6c-0.4,0.3-0.8,0.6-1.2,1c-0.6,0.3-1.3,0.2-1.8-0.3c-0.1-0.1-0.1-0.1-0.1-0.2
					c-0.2-0.3-0.2-0.7-0.1-1.1c0.1-0.3,0.3-0.6,0.6-0.8c0,0,0,0,0,0c4.7-3.6,9.3-9.1,8.6-15.4c-0.7-5.1-4.7-9.2-9.9-10
					c-0.1,0-0.2,0-0.3-0.1c-0.4-0.1-0.7-0.5-0.7-0.9c0-0.4,0-0.7,0.2-1c0.3-0.5,0.9-0.8,1.5-0.7c0.6,0.1,1.1,0.2,1.7,0.4
					c5.5,1.7,9.5,6.4,10.2,12C155.9,140.4,151.5,146.4,146.4,150.6z"/>
				<path class="hand__outline" d="M148.8,158.4c-0.3,2.5-1.6,4.7-3.4,6.3c-0.5,0.4-1.2,0.8-1.8,1.1c-0.3,0.1-0.7,0.1-0.9-0.1
					c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.2-0.3-0.3-0.5c-0.2-0.7,0.2-1.5,1-1.7c0.2-0.1,0.4-0.2,0.6-0.3c1.6-1,2.4-3.2,2.7-5
					c0.3-2.3-1.1-4.7-2.7-6.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.4-0.3-1,0-1.4c0.4-0.7,1.2-1,1.9-0.6c0.1,0.1,0.2,0.1,0.3,0.2
					c0.2,0.2,0.5,0.5,0.7,0.7c0.1,0.1,0.2,0.2,0.3,0.4C148.2,152.8,149.1,155.6,148.8,158.4z"/>
				<path class="hand__outline" d="M146.2,175c-2.6,6.5-9.3,11-15.7,13.3c-6.3,2.3-13.2,2.2-19.4-0.2c-0.3-0.1-0.5-0.2-0.8-0.3
					c-0.4-0.2-0.7-0.5-0.7-1c0-0.4,0.2-0.9,0.5-1.1c0.2-0.2,0.5-0.4,0.7-0.4c0,0,0,0,0,0c0.3-0.1,0.6,0,0.9,0.1c3,1.3,6.3,2,9.6,1.9
					c6-0.2,11.8-2.3,16.5-6c4.3-3.4,9.2-10.2,4.8-15.5c0,0,0,0-0.1-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5-1.3,1.1-2.8,2.2-1.6
					c0,0,0,0,0,0c0.3,0.3,0.6,0.7,0.8,1.1C147.3,167.8,147.6,171.7,146.2,175z"/>
				<path class="hand__outline" d="M111.6,186.9c0.1,0.5-0.1,0.9-0.5,1.2c-0.2,0.1-0.3,0.2-0.5,0.3c-6.6,2.8-13.7,4.5-20.8,5.2
					c-23.8,2.3-47.4-6.2-64.2-23.2c0,0,0,0,0,0c-0.5-0.6-0.4-1.5,0.2-2c0.5-0.4,1.3-0.4,1.8,0c1.3,1.3,2.5,2.5,3.9,3.7
					c3.3,2.9,6.9,5.6,10.7,7.9c19.6,12,44.8,14.7,66.3,6.3c0.5-0.2,1-0.4,1.5-0.6c0.1,0,0.1,0,0.2-0.1c0.7,0,1.3,0.4,1.5,1
					C111.6,186.8,111.6,186.8,111.6,186.9z"/>
				<path class="hand__outline" d="M119.3,95.4c-7.8,6.6-19,3.4-28.2,4.7c-7.2,1-15.1,5.4-15.4,13.5c-0.3,8.7,7.1,14.6,14.6,17.1
					c8.1,2.7,17.3,3.2,25.7,2.1c9.8-1.2,19.2-4.7,27.4-10.1c1-0.7,0.1-2.3-0.9-1.6c-14,9.2-31.1,12.5-47.5,8.9
					c-7.6-1.7-16.3-6-17.3-14.7c-1-8.4,7.4-12.8,14.6-13.5c9.5-1,20.4,1.7,28.4-5.1C121.6,96,120.2,94.7,119.3,95.4L119.3,95.4z"/>
				<path class="hand__outline" d="M86.4,128.3c-4.2,2.1-5.9,7.3-4.8,11.7c1.3,5.1,5.7,8.7,10.4,10.6c5.5,2.3,11.7,3,17.6,3.7
					c6.1,0.7,12.2,0.8,18.3,0.3c6.6-0.6,13.1-2.2,19.3-4.8c1.1-0.5,0.2-2.1-0.9-1.6c-10.1,4.3-21.2,5.4-32.1,4.7
					c-5.5-0.3-10.9-1.1-16.3-2.3c-4.9-1.2-10.1-3.3-13-7.7c-2.7-4.2-2.4-10.4,2.5-12.9C88.4,129.4,87.5,127.8,86.4,128.3L86.4,128.3z"
					/>
				<path class="hand__outline" d="M143.1,162.8c-8.9,3.8-18.1,7.9-27.8,8.5c-8.2,0.5-17.7-1.4-23.4-7.9
					c-1.7-1.7-2.6-4.1-2.7-6.5c0.1-2.4,1.1-4.6,2.9-6.2c0.9-0.8-0.4-2.1-1.3-1.3c-3.3,3-4.4,7.7-2.6,11.8c1.9,4.2,6,7.1,10,9
					c8.9,4.1,19.1,3.5,28.4,0.9c6-1.8,11.8-4.1,17.5-6.7C145.1,164,144.2,162.4,143.1,162.8L143.1,162.8z"/>
				<path class="hand__outline" d="M100.1,169.5c-3.8,2.5-3.3,7.7-0.6,10.8c1.5,1.7,3.3,3.1,5.3,4.2c1.8,1.1,3.8,2,5.9,2.6
					c0.5,0.1,1-0.2,1.2-0.7c0.1-0.5-0.2-1-0.7-1.2c-3.6-1.1-7-3-9.7-5.6c-1.2-1.1-2-2.6-2.3-4.2c-0.3-1.7,0.4-3.4,1.8-4.4
					C102,170.4,101.1,168.8,100.1,169.5L100.1,169.5z"/>
				<path class="hand__outline" d="M64.3,102.9c-1.9,13.5-4.2,29.3-16.1,37.9c-1.5,1.1,0,3.5,1.4,2.4
					c12.6-9.2,15.4-25.2,17.4-39.6c0.2-0.7-0.3-1.5-1-1.7C65.3,101.7,64.5,102.1,64.3,102.9L64.3,102.9z"/>
				<path class="hand__outline" d="M8.2,105.6"/>
				<path class="hand__outline" d="M137.2,89.6c-0.2,0.4-0.5,0.8-0.8,1.2c-0.3,0.5-0.7,0.9-1,1.3c-3.6,4-9,5.2-14.2,5.4
					c-0.5,0-1,0-1.5,0c-1.1,0-2.3,0-3.4,0c-1,0-2-0.1-2.9-0.1c-3.5-0.1-6.9-0.1-10.4-0.1c-7.8,0-15.6-0.1-23.4-0.1
					c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c3.6,0,7.2,0,10.8,0c7.3,0,14.6-0.1,21.8,0.1c4.9,0.1,10,0.6,14.9-0.6
					c5.3-1.3,9.2-5.9,9.6-11.4c0.5-6.3-3.6-11.4-9-14.2c-6-3.2-12.7-3.1-19.3-3.5c-7.4-0.3-14.8-0.8-22.2-1.3c-7-0.4-14.1-1.5-21-0.4
					c-5.4,0.8-10.6,3.1-15.6,5.3c-5.1,2.1-10,4.7-14.7,7.5c-5.5,3.5-10.5,7.9-14.7,12.9C12,98.5,6.7,110.2,4.6,122.6
					c-0.2,1-0.3,2-0.5,3c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1v0c0,0,0,0,0,0v0l0,0.1c0,0,0,0.1,0,0.1l-0.1,1c-0.1,0.9-0.2,1.7-0.2,2.6
					c-0.3,4.9,0.1,9.9,1.3,14.7c1.5,6.1,4.6,11.6,9.1,16c6.3,5.9,14.7,8.7,23,9.8c0.7,0.2,1.2,1,1,1.7c-0.2,0.7-1,1.2-1.7,1
					c-1.7-0.2-3.4-0.5-5.1-0.9c-0.8-0.2-1.6-0.4-2.4-0.6c-1.2-0.3-2.3-0.7-3.5-1.1c-6.7-2.3-12.5-6.4-16.9-12
					c-3.8-5.1-6.2-11.2-7.1-17.5c-0.7-4.7-0.8-9.4-0.3-14.1c0.6-5.6,1.8-11.1,3.6-16.4C9.2,98,16.7,86.5,27,78.1
					c4.8-3.7,10-6.9,15.5-9.5c5.3-2.7,10.7-4.9,16.3-6.8c6.6-2.1,13.4-2.1,20.2-1.6c7.7,0.6,15.4,1,23.1,1.4c3.8,0.2,7.7,0.4,11.5,0.5
					c3.3,0.1,6.6,0.5,9.8,1.4c5.9,1.6,11.9,5.3,14.6,11C140.1,79.4,139.9,85,137.2,89.6z"/>
				<path class="hand__outline" d="M120,96.1"/>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			required: true,
		},
		isOpen: Boolean,
	},
}
</script>
