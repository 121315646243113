<template functional>
	<svg :class="[ 'thumbemote', 'thumbemote--' + props.face ]" viewBox="0 0 308 402">
		<g class="thumbemote__hand">
			<path class="thumbemote__hand__backfill" d="M303.9,189.5c-1.2-2-2.6-3.9-4.2-5.6c4.5-7.1,6.5-15.8,5.6-25c-1-10.5-5.3-20.2-12.6-28
				c-7.1-7.7-16.7-13.2-27.7-15.8c-11.9-2.8-23.6-1.6-34.6,0.2c-1.8,0.3-3.5,0.6-5.3,0.9c-7.3,1.3-14.9,2.6-22,2
				c4.1-15.6,5.3-31.7,6-46.8c0.1-2.3,0.2-4.6,0.4-6.9c0.4-7.2,0.8-14.6,0.4-21.9c-0.7-10.7-3.4-19.7-8-26.8
				C196.5,7.5,188.3,2,179.5,0.6c-9.6-1.5-19.1,2-25.4,9.3c-6.8,8-8.8,18.1-9.9,26c-0.4,3.4-0.8,6.8-1.2,10.1
				c-0.7,5.8-1.4,11.9-2.3,17.7c-3.2,19.6-7.8,36.8-14.1,52.7c-0.6,1.5-1.3,3.1-1.9,4.7c-12.1,8-22.3,18.1-30.3,30.2
				c-9.5,14.3-15.8,30.4-18.3,46.5c-1.4,8.7-1.7,17.5-1,26.4c-13.8,11.3-26.3,24.3-37.1,38.5c-11.5,15.2-20.9,31.8-27.9,49.2
				c-3.8,9.6-7,19.5-9.3,29.4c-0.7,1.3-0.9,2.7-0.7,4.1c0.3,1.9,1.5,3.6,3.3,4.6l95.2,51c1,0.5,2,0.8,3.1,0.8c1.2,0,2.4-0.3,3.4-1
				c1.6-1,2.7-2.6,3-4.3c0.2-0.5,0.3-1,0.3-1.5c2.2-21.6,9.8-44.5,23.8-72c2.2-4.3,4.6-8.8,7.3-13.6c21.8,10.4,47.2,12.9,70.4,6.7
				c7.3-1.9,14.4-4.7,21.2-8.3c13.5,1.5,26.6,2.4,39.2-2c6.6-2.3,12.2-5.8,16.6-10.2c4.4-4.5,7.5-10.2,8.8-16.5
				c1.1-5.3,0.9-10.5-0.5-15.3c4.9-4.2,8.1-10.4,8.9-17.4c0.8-6.6-1-13.5-4.9-18.9C308.6,217.9,311,201.6,303.9,189.5z"/>
			<g class="thumbemote__hand__fill">
				<path d="M217.3,288c-1.3-4.7-1-11.1,2.9-14.1c-1.8-0.8-3.6-1.8-5.2-3c-5.5-4-9.6-9.8-10.3-16.6
					c-0.6-6,1.5-12.1,5.9-16.1c-5.9-3.3-10.4-8.8-12.3-15.4c-2-7.2-0.5-14.8,3.6-20.8c-5.5-1.5-10.5-4.4-14-9
					c-3.6-4.9-5.3-11.3-4.5-17.3c0.8-6.1,3.8-11.9,8.3-16.1c4.5-4.2,10.2-6.6,16.2-7.9c12.1-2.7,25.7-1.3,36.5-8.2c1.5-1,3,1.5,1.4,2.4
					c-11.5,7.3-25.6,5.7-38.4,8.8c-5.7,1.4-11.1,3.9-15.1,8.2s-6.4,10.1-6.3,16c0.1,6,2.8,11.9,7.4,15.7c3.1,2.6,6.8,4.1,10.7,4.9
					c0.2,0,0.3,0,0.5,0.1c2,0.4,4,0.7,6,0.8c6.6,0.5,13.2,0.1,19.7-0.5c7-0.6,13.9-1.8,20.8-3.3c13.4-3,26.5-7.7,38.8-13.9
					c6.8-7.5,8.4-18.1,6.3-27.8c-4-18.8-21.6-31-40.1-32.9c-11-1.1-21.8,1.2-32.6,2.9c-9.8,1.5-19.4,3.1-29.1,0.1
					c-0.3-0.1-0.6-0.2-0.8-0.4c-0.5-0.4-0.8-1.1-0.6-1.9c3-9.2,5-18.8,6.1-28.4c1.1-9.5,1.4-19,1.8-28.5c0.7-16.5,3-36.1-9.2-49.3
					c-5-5.5-12.6-8.9-20.1-7.7c-7.4,1.2-12.7,7-15.6,13.6c-3.6,8.2-3.9,17.5-4.9,26.2c-1,8.9-2.3,17.7-4,26.4
					c-3.4,17.7-8.5,35-15.8,51.5c-0.3,0.6-0.7,0.9-1.1,1c-6.6,4.2-12.7,9.1-18.1,14.8c-21.2,22.2-31.7,53.9-28.7,84.3
					c0.2,0.4,0.2,0.9,0,1.3c-0.1,0.4-0.5,0.7-0.9,0.9c-7.9,6.3-15.4,13.2-22.4,20.5c-12.6,13.3-23.6,28.2-32.4,44.3
					c-8.6,15.7-15.1,32.5-19.1,50c6.5,3.5,13.1,7,19.6,10.5c23.9,12.8,47.9,25.6,71.8,38.4c0.8-6.6,2-13.2,3.6-19.7
					c6.2-25.2,17.7-48.9,30.4-71.4c-0.9-0.6-1.9-1.1-2.8-1.7c-2-1.3-0.2-4.6,1.9-3.3c6.1,3.8,12.5,7,19.3,9.4
					c24.1,8.4,50.9,6.5,73.6-5.1C222,297.7,218.6,293.2,217.3,288z M168.3,241.9c-0.9,1.6-3.3,0.2-2.4-1.4
					c7.8-14.2,11.9-30.2,11.8-46.5c0-1.8,2.8-1.8,2.8,0C180.6,210.8,176.3,227.3,168.3,241.9z"/>
				<path d="M288.3,228c-7.7,5-16,8.8-24.8,11.4c-8.2,2.4-16.7,3.7-25.2,3.9c-8.3,0.1-17.1-0.5-24.8-3.7
					c-0.1,0-0.1-0.1-0.2-0.1c-5.5,4.1-7.1,11.9-5,18.2c2,6.2,7.2,10.9,13,13.6c6.8,3.2,14.6,3.6,22,3.1c15-1.2,29.6-6.2,42.3-14.3
					c0.2-0.2,0.4-0.4,0.7-0.5c5.6-2.8,8.9-8.3,9.6-14.5C296.4,238.5,293.5,232.1,288.3,228z"/>
				<path d="M289.8,186.4c-0.1-0.1-0.2-0.2-0.3-0.3c-14,6.8-28.9,11.8-44.2,14.6
					c-7.2,1.3-14.6,2.2-21.9,2.5c-6.1,0.3-12.4,0.4-18.4-0.7c-5.1,6.7-6.1,16-2.2,23.6c3.6,7,10.1,10.9,17.6,12.6
					c7.6,1.8,15.7,2,23.5,1.4c15.8-1.2,31.3-6.6,44.4-15.4c0.2-0.2,0.4-0.2,0.7-0.2c6.7-3.5,10.4-10.6,10.9-18.1
					C300.2,198.6,296.3,190.8,289.8,186.4z"/>
				<path d="M262.2,273.7c-8.1,2.3-16.7,3.8-25.2,3.6c-4.5-0.1-9.1-0.7-13.3-2.1c-5.3,1.6-4.7,9-3.3,13.2
					c1.5,4.5,4.7,8.3,8.9,10.7c7.1,0.8,14.3,1.7,21.5,1.5c11-0.2,22.7-3.1,30.5-11.3c6.4-6.7,8.8-17.6,4.8-26.1
					C278.6,267.7,270.6,271.3,262.2,273.7z"/>
			</g>
			<g class="thumbemote__hand__outline">
				<path fill="#5F0F3F" d="M177.7,194c0.1,16.2-4,32.3-11.8,46.5c-0.9,1.6,1.6,3,2.4,1.4
					c8.1-14.6,12.3-31.2,12.2-47.9C180.5,192.2,177.7,192.2,177.7,194z"/>
				<path fill="#5F0F3F" d="M292,226.6c11.3-7,14.2-23.4,7.6-34.6c-1.7-2.9-4-5.4-6.6-7.5c6-7,8.3-16.1,7.4-25.2
					c-0.9-9.4-4.9-18.1-11.3-25.1c-6.7-7.2-15.6-12.1-25.2-14.3c-10.8-2.6-21.7-1.5-32.6,0.3c-11.3,1.9-23.1,4.8-34.4,2
					c5.2-16.6,6.6-33.8,7.4-51c0.4-9.4,1.3-18.9,0.7-28.3c-0.5-8.5-2.5-17.1-7.2-24.3c-4.4-6.6-11-11.7-19-13
					c-7.9-1.3-15.6,1.5-20.8,7.6c-5.6,6.6-7.6,15-8.7,23.4c-1.2,9.3-2,18.7-3.5,28c-3,18.3-7.5,36.5-14.4,53.8
					c-0.8,2.1-1.7,4.2-2.6,6.2c-12,7.7-22.2,17.7-30.1,29.5c-8.8,13.4-15.1,28.7-17.6,44.5c-1.5,9.2-1.7,18.5-0.8,27.8
					C66,237.9,53.1,251.1,42,265.7c-11.1,14.7-20.4,30.9-27.3,48.1c-3.9,9.6-7,19.5-9.3,29.6c-0.6,0.6-0.7,1.8,0.3,2.3
					c24.7,13.2,49.5,26.5,74.2,39.7c7,3.8,14,7.5,21.1,11.3c1.2,0.6,2.3-0.4,2.3-1.5c0.1-0.2,0.2-0.4,0.2-0.7
					c2.7-26,12.5-50.7,24.3-73.8c3.1-6.1,6.4-12.1,9.7-18c21.6,11.7,47.3,14.8,71.1,8.5c7.3-2,14.4-4.8,21.1-8.4
					c0.1-0.1,0.2-0.1,0.3-0.2c12.8,1.5,26.1,2.8,38.5-1.6c5.5-1.9,10.6-4.9,14.7-9c3.8-3.8,6.3-8.7,7.4-14c1.1-5.4,0.7-11.1-1.5-16.2
					c5.8-3.4,9.2-9.5,10-16.2C300,238.6,297.2,231.4,292,226.6z M300.1,206.4c-0.5,7.5-4.2,14.8-11,18.4c-0.2,0-0.5,0-0.7,0.2
					c-13.2,8.9-28.8,14.3-44.7,15.5c-7.9,0.6-16.1,0.4-23.8-1.5c-7.5-1.8-14.1-6-17.6-13c-3.8-7.7-2.7-17,2.5-23.7
					c6.3,1.2,12.9,1.1,19.3,0.7c7.2-0.4,14.4-1.2,21.4-2.5c15.3-2.8,30.2-7.7,44.1-14.6c0.1,0.1,0.2,0.2,0.3,0.3
					C296.6,190.5,300.6,198.5,300.1,206.4z M152.6,306.1c-6.8-2.4-13.3-5.6-19.4-9.4c-1.6-1-3.1,1.6-1.5,2.6c1,0.7,2.1,1.3,3.1,1.9
					c-12.8,22.6-24.3,46.3-30.6,71.5c-1.7,6.7-2.9,13.5-3.6,20.3c-24.1-12.9-48.2-25.8-72.3-38.7c-6.6-3.5-13.2-7.1-19.9-10.6
					C12.5,326,19,309,27.7,293.2c8.8-16.1,19.7-30.9,32.3-44.2c7-7.4,14.5-14.3,22.5-20.6c0.3-0.1,0.6-0.4,0.7-0.7
					c0.2-0.3,0.2-0.7,0-1c-1.7-16.3,0.4-32.8,6.2-48.2c5.1-13.5,12.7-26.3,22.7-36.7c5.4-5.6,11.5-10.6,18.1-14.8
					c0.4-0.1,0.7-0.3,0.9-0.8c7.2-16.5,12.3-33.8,15.7-51.4c1.7-8.8,3-17.6,4-26.5c1-8.7,1.2-18,4.9-26.2c3-6.8,8.5-12.7,16.1-13.9
					c7.6-1.1,15.3,2.4,20.3,8c12.2,13.3,9.9,32.9,9.2,49.4c-0.7,17-1.4,34.1-6,50.6c-0.6,2.2-1.3,4.3-2,6.5c-0.2,0.7,0,1.2,0.4,1.5
					c0.2,0.1,0.4,0.3,0.6,0.3c4.7,1.4,9.6,2,14.6,1.7c5.1-0.3,10.2-1.2,15.3-2c10.6-1.7,21.3-3.9,32.1-2.7c9.6,1.1,19.1,4.7,26.4,10.9
					c6.7,5.7,11.8,13.5,13.6,22.2c2.1,9.8,0.6,20.5-6.2,28.1c-12.3,6.2-25.4,10.9-38.8,14c-7,1.6-14,2.7-21.2,3.4
					c-6.5,0.6-13.2,1-19.8,0.5c-2-0.1-4-0.4-5.9-0.8c-0.1-0.1-0.3-0.1-0.4-0.1c-3.9-0.9-7.6-2.3-10.7-4.8c-4.8-3.9-7.5-9.9-7.7-16
					c-0.2-6,2.3-11.9,6.3-16.3c4.1-4.5,9.6-7,15.5-8.4c12.6-3,26.8-1.4,38.1-8.7c1.2-0.8,0.1-2.7-1.1-1.9c-10.7,6.9-24.1,5.6-36.1,8.1
					c-6,1.3-11.8,3.6-16.3,7.7c-4.5,4.1-7.6,9.7-8.4,15.8c-0.8,6.1,0.8,12.6,4.5,17.5c3.5,4.6,8.6,7.5,14.1,8.9
					c-4.2,6-5.8,13.6-3.9,20.8c1.8,6.8,6.4,12.3,12.5,15.6c-4.5,3.9-6.6,9.9-6.1,15.8c0.6,6.9,4.8,12.8,10.3,16.8
					c1.7,1.2,3.6,2.3,5.5,3.1c-4.3,2.9-4.5,9.5-3.2,14.2c1.4,5.2,4.8,9.8,9.4,12.6v0.1C204.1,312.6,177,314.6,152.6,306.1z
					M281.7,289.3c-7.9,8.4-19.7,11.4-30.8,11.7c-7.2,0.2-14.4-0.6-21.6-1.5l0,0c-4.3-2.3-7.6-6.3-9.2-11c-1.5-4.3-2-11.9,3.6-13.6
					c4.5,1.5,9.2,2.1,13.9,2.1c8.3,0.1,16.6-1.4,24.6-3.7c8.5-2.5,16.6-6.1,24.1-10.8C290.4,271.2,288.1,282.5,281.7,289.3z
					M296.2,245.2c-0.7,6.2-4.1,11.8-9.7,14.6c-0.1,0.1-0.2,0.1-0.3,0.2c-12.7,8.2-27.5,13.3-42.6,14.5c-7.7,0.6-15.8,0.2-22.8-3.2
					c-5.8-2.8-11-7.7-12.9-14c-1.9-6.4-0.2-14.2,5.4-18.2c0.1,0,0.2,0.1,0.2,0.1c7.7,3.3,16.5,3.8,24.8,3.7c8.5-0.1,17-1.4,25.1-3.8
					c8.9-2.6,17.4-6.5,25.1-11.6l0.1,0.1C293.9,231.8,296.9,238.5,296.2,245.2z"/>
				<path fill="#5F0F3F" d="M178,194c0.1,16.3-4,32.4-11.9,46.6c-0.7,1.3,1.2,2.4,1.9,1.1c8-14.6,12.3-31.1,12.2-47.7
					C180.2,192.6,178,192.6,178,194z"/>
			</g>
		</g>
		<g class="thumbemote__face" v-if="props.face === 'very-upset'">
			<path fill="#5F0F3F" d="M188.6,51.7c-1-2-2.5-3.7-5-3.4c-2.1,0.3-3.6,1.6-4.9,3.2C181.9,50.6,185.4,50.6,188.6,51.7z"/>
			<path fill="#F59884" d="M171.1,50c2.6,0,2.6-4,0-4S168.5,50,171.1,50L171.1,50z"/>
			<path fill="#F59884" d="M193.8,48.7c2.6,0,2.6-4,0-4S191.2,48.7,193.8,48.7L193.8,48.7z"/>
			<path fill="#F59884" d="M182.6,51.6c0.3-0.2,0.5-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.9-0.1c0.6,0,1.1,0.2,1.6,0.5c0.2,0.2,0.6,0,0.7-0.2
				c0.1-0.3,0-0.5-0.2-0.7c-1.2-0.9-3-0.9-4.2,0c-0.2,0.2-0.3,0.4-0.2,0.7C182,51.6,182.4,51.8,182.6,51.6L182.6,51.6z"/>
			<path fill="#5F0F3F" d="M184.6,27.5c5.4,5.2,10.7,1.7,4.3,0.1c-0.5-0.3-1.4-0.8-2-1.2c-0.1,0-0.1-0.1-0.2-0.1
				C185.7,24.8,183.3,25.6,184.6,27.5L184.6,27.5z"/>
			<path fill="#5F0F3F" d="M172.9,26.4C172.8,26.7,173,26.2,172.9,26.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.2,0.3-0.4,0.7-0.5,1
				c-0.3,0.2-0.8,0.9-1.3,1.1c0,0-0.3,0.2,0,0c-0.5,0.4-0.9,0.6-1.4,0.8c-0.1,0.1-0.2,0.1,0,0c-0.9,0.5-2.8,0.1-2.8,1.5
				c0,0.6,0.5,1.2,1.1,1.1c2.6,0,9.2-4.3,6.3-6.9C173.7,25.5,173.2,25.8,172.9,26.4L172.9,26.4z"/>
			<path fill="#5F0F3F" d="M192,41.8c-0.7-0.6-1.5-1.3-2.2-1.9c-0.5-0.4-1.2-1-1.6-1.5c-0.1-0.1-0.1-0.1-0.2-0.2
				c0.4-0.2,0.7-0.4,1.2-0.6c0.9-0.5,1.7-1,2.6-1.5c0.5-0.3,0.7-1,0.4-1.5c-0.4-0.5-1.1-0.7-1.6-0.4c-1,0.6-2,1.1-3,1.7
				c-1,0.6-2.4,1.3-2,2.7c0.1,0.6,0.5,1,0.9,1.3c0.4,0.4,0.9,0.8,1.3,1.2c0.9,0.8,1.7,1.5,2.6,2.3c0.5,0.4,1.1,0.4,1.6,0
				C192.4,43,192.5,42.2,192,41.8z M187.7,38.4C187.7,38.4,187.7,38.4,187.7,38.4C187.8,38.4,187.8,38.4,187.7,38.4
				C187.8,38.4,187.8,38.4,187.7,38.4z"/>
			<path fill="#5F0F3F" d="M177.9,36.6c-2.2-0.4-4.3-0.8-6.5-1.2c-0.6-0.1-1.2,0.2-1.4,0.8c-0.1,0.5,0.2,1.2,0.8,1.4
				c1.5,0.3,3.1,0.6,4.6,0.8c-1.2,1.2-2.3,2.5-3.5,3.8c-0.4,0.4-0.5,1.2,0,1.6c0.4,0.4,1.2,0.5,1.6,0c1.6-1.8,3.3-3.5,4.9-5.3
				C178.9,37.9,178.8,36.8,177.9,36.6z"/>
			<path fill="#5F0F3F" d="M192.4,54.3c-0.7-2.1-1.4-4.3-2.9-6c-1.4-1.6-3.4-2.6-5.6-2.5c-4.7,0.3-7.5,4.3-9.4,8.2
				c-0.7,1.1,0.8,2.2,1.6,1.4c0.2,0,0.4,0,0.6-0.2c4.3-2.5,9.8-2.3,14,0.4C191.5,56.1,192.7,55.2,192.4,54.3z M177.9,52.2
				c1.4-2.1,3.1-3.8,5.7-4.1c2.9-0.4,4.5,1.8,5.6,4.2C185.6,50.9,181.5,50.9,177.9,52.2z"/>
		</g>
		<g class="thumbemote__face" v-if="props.face === 'upset'">
			<path fill="#F59884" d="M174.2,58.3c3.9,0,3.9-6,0-6S170.3,58.3,174.2,58.3L174.2,58.3z"/>
			<path fill="#F59884" d="M195.9,58.5c3.9,0,3.9-6,0-6S192,58.5,195.9,58.5L195.9,58.5z"/>
			<path fill="#5F0F3F" d="M195.5,42.5c-0.3-1.3-1.1-2.3-2.3-2.8c-0.7-0.3-1.5-0.3-2.2-0.1c-1.4,0.5-2.2,1.9-2.5,3.3
				c-0.3,1.2-0.4,2.7-0.2,3.9c0,0.2-0.1,0.4,0,0.6c0.4,1.2,1.1,2.2,2.3,2.7c1.2,0.4,2.4,0,3.4-0.7c0.9-0.8,1.4-1.9,1.6-3.1
				C195.8,45.1,195.8,43.7,195.5,42.5z"/>
			<path fill="#5F0F3F" d="M179.6,40c-0.7-0.6-1.7-1-2.7-0.9c-1.6,0.2-2.6,1.5-3,3c-0.3,1.4-0.4,3.1-0.1,4.5c0.6,2.2,2.9,3.9,5.2,2.9
				c2.4-1.1,2.6-4,2.2-6.3C181,42,180.5,40.8,179.6,40z"/>
			<path fill="#5F0F3F" d="M178.6,31.5c-0.2,0.3-0.5,0.4-0.7,0.7c0,0,0.2-0.1,0.1,0c-0.3,0.3-0.9,0.5-1.2,0.7c0,0,0.2-0.1,0.1,0
				c-0.4,0.2-0.8,0.2-1.2,0.4h-0.1c-0.2,0,0,0,0.1,0c-1.7-0.5-2.6,1.9-0.7,2.2c1.9,0,6.7-1.5,5.4-4C179.8,31.1,179.1,31,178.6,31.5
				L178.6,31.5z"/>
			<path fill="#5F0F3F" d="M187.3,32.4c1.4,1.2,6,4,6.9,1.3c0-0.9-0.7-1.2-1.4-1.1c-0.8,0-2.7-0.6-3.7-1.5
				C188.5,30.2,186,30.6,187.3,32.4L187.3,32.4z"/>
			<path fill="#5F0F3F" d="M183.3,60.4c0.4-0.8,0.9-1.5,1.5-2.1c0.5-0.5,1.1-0.9,1.9-1c-0.1,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0
				c0,0,0.3,0,0.1,0c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.3,0.1,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0,0,0.2,0.1,0.1,0c0.6,0.5,0.9,1,1,1.8
				c0.1,0.6,0.9,0.9,1.4,0.8c0.6-0.2,0.9-0.8,0.8-1.4c-0.3-2.3-2.5-4-4.8-3.9c-2.5,0.2-4.1,2.2-5.2,4.2c-0.3,0.5-0.1,1.2,0.4,1.5
				C182.2,61.1,183,61,183.3,60.4L183.3,60.4z"/>
			<path fill="#FFFFFF" d="M177.2,44.1c1.3,0,1.3-2,0-2S175.9,44.1,177.2,44.1L177.2,44.1z"/>
			<path fill="#FFFFFF" d="M191.8,44c1.3,0,1.3-2,0-2S190.5,44,191.8,44L191.8,44z"/>
		</g>
		<g class="thumbemote__face" v-if="props.face === 'neutral'">
			<path fill="#5F0F3F" d="M195.5,42.5c-0.3-1.3-1.1-2.3-2.3-2.8c-0.7-0.3-1.5-0.3-2.2-0.1c-1.4,0.5-2.2,1.9-2.5,3.3
				c-0.3,1.2-0.4,2.7-0.2,3.9c0,0.2-0.1,0.4,0,0.6c0.4,1.2,1.1,2.2,2.3,2.7c1.2,0.4,2.4,0,3.4-0.7c0.9-0.8,1.4-1.9,1.6-3.1
				C195.8,45.1,195.8,43.7,195.5,42.5z"/>
			<path fill="#5F0F3F" d="M181.2,43.2c-0.2-1.2-0.7-2.4-1.6-3.2c-0.7-0.6-1.7-1-2.7-0.9c-1.6,0.2-2.6,1.5-3,3
				c-0.3,1.4-0.4,3.1-0.1,4.5c0.6,2.2,2.9,3.9,5.2,2.9C181.4,48.4,181.6,45.5,181.2,43.2z"/>
			<path fill="#FFFFFF" d="M192.1,43.7c0.6,0,0.6-1,0-1C191.4,42.7,191.4,43.7,192.1,43.7L192.1,43.7z"/>
			<path fill="#FFFFFF" d="M177,43.4c0.6,0,0.6-1,0-1S176.4,43.4,177,43.4L177,43.4z"/>
			<path fill="#F59884" d="M173.7,58.4c3.9,0,3.9-6,0-6S169.8,58.4,173.7,58.4L173.7,58.4z"/>
			<path fill="#F59884" d="M195.2,58.2c3.9,0,3.9-6,0-6S191.3,58.2,195.2,58.2L195.2,58.2z"/>
			<path fill="#5F0F3F" d="M174.7,35.8c0.7,0,1.4-0.1,2.1-0.1c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8
				s-0.5-0.3-0.8-0.3c-0.7,0-1.4,0.1-2.1,0.1c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8
				C174.1,35.7,174.4,35.8,174.7,35.8L174.7,35.8z"/>
			<path fill="#5F0F3F" d="M191.9,35.9c0.6,0,1.2-0.1,1.9-0.1c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8
				s-0.5-0.3-0.8-0.3c-0.6,0-1.2,0.1-1.9,0.1c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8
				C191.4,35.8,191.6,35.9,191.9,35.9L191.9,35.9z"/>
			<path fill="#5F0F3F" d="M184,59.6c1.3-0.1,2.6-0.1,4-0.2c0.6,0,1.2-0.5,1.1-1.1c0-0.6-0.5-1.2-1.1-1.1c-1.3,0.1-2.6,0.1-4,0.2
				c-0.6,0-1.2,0.5-1.1,1.1C182.9,59,183.3,59.6,184,59.6L184,59.6z"/>
		</g>
		<g class="thumbemote__face" v-if="props.face === 'happy'">
			<path fill="#5F0F3F" d="M195.7,42.5c-0.3-1.4-1.2-2.5-2.6-3c-0.7-0.3-1.6-0.4-2.3-0.1c-1.5,0.5-2.3,2-2.7,3.5
				c-0.4,1.5-0.5,3.2-0.1,4.6c0.4,1.3,1.3,2.4,2.5,2.9c1.3,0.5,2.6,0,3.7-0.8c0.9-0.8,1.5-2,1.6-3.2C196,45.1,196,43.7,195.7,42.5z"/>
			<path fill="#5F0F3F" d="M181.5,43.1c-0.2-1.2-0.7-2.5-1.7-3.3c-0.8-0.7-1.8-1.1-2.9-1c-1.7,0.2-2.7,1.7-3.2,3.2
				c-0.4,1.5-0.5,3.2-0.2,4.7c0.7,2.4,3.2,4.2,5.7,3S181.9,45.5,181.5,43.1z"/>
			<path fill="#5F0F3F" d="M191.5,54.9c-1.7-1.2-3.7-0.6-5.5-0.2h-0.2c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.7,0c-0.6,0-1.1,0-1.7,0
				c-1.2,0-2.4,0.2-3.4,0.9c-1.3,0.9-2,2.4-1.7,4c0.2,1.5,1.1,2.8,2.1,3.9c2,2,5.3,3.2,8.2,2.3c2.8-0.9,5-3.4,5.2-6.4
				C193.6,57.7,192.9,55.9,191.5,54.9z"/>
			<path fill="#63CD9A" d="M191.9,42L191.9,42L191.9,42C191.8,42,191.8,42,191.9,42C191.8,42,191.8,42,191.9,42L191.9,42L191.9,42z"/>
			<path fill="#63CD9A" d="M191.6,42.1L191.6,42.1C191.5,42.2,191.5,42.2,191.6,42.1L191.6,42.1z"/>
			<path fill="#63CD9A" d="M190.7,44.5L190.7,44.5C190.7,44.4,190.7,44.3,190.7,44.5z"/>
			<path fill="#63CD9A" d="M191.9,42L191.9,42C192,42,192,42,191.9,42z"/>
			<path fill="#63CD9A" d="M191.7,42L191.7,42C191.7,42.1,191.7,42.1,191.7,42C191.7,42.1,191.7,42.1,191.7,42
				C191.7,42.1,191.7,42.1,191.7,42z"/>
			<path fill="#5F0F3F" d="M191.7,42.1L191.7,42.1c-0.1,0-0.1,0.1-0.1,0.1C191.6,42.2,191.6,42.1,191.7,42.1z"/>
			<path fill="#5F0F3F" d="M192,42C191.9,42,191.9,42,192,42C191.9,42,191.9,42,192,42c-0.1,0-0.1,0-0.1,0S191.9,42,192,42z"/>
			<path fill="#5F0F3F" d="M176.2,43.8C176.2,43.9,176.2,44.1,176.2,43.8L176.2,43.8z"/>
			<path fill="#FFFFFF" d="M177.1,44.5c1.9,0,1.9-3,0-3C175.2,41.5,175.2,44.5,177.1,44.5L177.1,44.5z"/>
			<path fill="#FFFFFF" d="M191.5,44.6c1.9,0,1.9-3,0-3S189.6,44.6,191.5,44.6L191.5,44.6z"/>
			<path fill="#F59884" d="M197,56.4c3.9,0,3.9-6,0-6S193.1,56.4,197,56.4L197,56.4z"/>
			<path fill="#F59884" d="M171.9,56.8c3.9,0,3.9-6,0-6S168,56.8,171.9,56.8L171.9,56.8z"/>
			<path fill="#F59884" d="M183.6,63.2c0.9-0.3,2-0.6,2.9-0.6c1-0.1,2.1,0,2.9,0.1c0.5,0.1,1.1-0.1,1.2-0.7c0.1-0.5-0.1-1.1-0.7-1.2
				c-2.3-0.4-4.6-0.3-6.8,0.5c-0.5,0.2-0.9,0.7-0.7,1.2C182.5,63,183,63.4,183.6,63.2L183.6,63.2z"/>
			<path fill="#5F0F3F" d="M177.8,33.6c-0.3-0.2-0.6-0.4-1-0.4c-0.7,0-1.4,0.1-2.1,0.1c-0.4,0-0.7,0.1-1,0.4c-0.2,0.2-0.4,0.6-0.4,1
				c0,0.4,0.1,0.7,0.4,1c0.2,0.2,0.6,0.4,1,0.4c0.7,0,1.4-0.1,2.1-0.1c0.4,0,0.7-0.1,1-0.4c0.2-0.2,0.4-0.6,0.4-1
				C178.2,34.2,178.1,33.9,177.8,33.6z"/>
			<path fill="#5F0F3F" d="M194.8,33.7c-0.3-0.2-0.6-0.4-1-0.4c-0.6,0-1.2,0.1-1.9,0.1c-0.4,0-0.7,0.1-1,0.4c-0.1,0.1-0.2,0.3-0.3,0.5
				c-0.1,0.2-0.1,0.4-0.1,0.5c0,0.4,0.1,0.7,0.4,1c0.3,0.2,0.7,0.4,1,0.4c0.6,0,1.2-0.1,1.9-0.1c0.4,0,0.7-0.1,1-0.4
				c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.5C195.2,34.3,195.1,34,194.8,33.7z"/>
		</g>
		<g class="thumbemote__face" v-if="props.face === 'very-happy'">
			<path fill="#5F0F3F" d="M181.5,64.2c0.6,0.2,1.3,0.3,2,0.3c2.5,0,5-1.3,6.4-3.4c0.7-1,1.3-2.2,1.2-3.4c-0.1-1.3-1.4-1.2-2.4-1.2
				c-2.1,0.1-4.2,0.2-6.2,0.3c-0.9,0-1.9,0-2.8,0c-0.9,0-1.7,0-2.6,0.3c-0.4,0.1-0.8,0.5-0.9,0.6c-0.2,0.2-0.1,0.2-0.2,0.4
				c0-0.2,0,0.3,0,0.3c0,0.2,0.2,0.7,0.3,1c0.2,0.5,0.5,0.9,0.8,1.3C178.1,62.4,179.7,63.6,181.5,64.2"/>
			<path fill="#F59884" d="M167,55.7c3.2,0,3.2-5,0-5C163.7,50.7,163.7,55.7,167,55.7L167,55.7z"/>
			<path fill="#F59884" d="M197.7,54.7c3.2,0,3.2-5,0-5S194.5,54.7,197.7,54.7L197.7,54.7z"/>
			<path fill="#5F0F3F" d="M180.7,64.3c0.3-0.1,0.6-0.2,0.9-0.2c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.6-0.1,0.3,0c0.6-0.1,1.2-0.1,1.8-0.1
				c0.3,0,0.6,0,0.9,0c0.2,0,0.3,0,0.5,0c0.4,0-0.3-0.1,0.1,0c0.6,0.1,1.2,0.3,1.9,0.4c0.7,0.2,1.7-0.3,1.8-1c0.2-0.8-0.2-1.6-1-1.8
				c-2.7-0.8-5.6-0.9-8.3-0.1c-0.8,0.2-1.3,1.1-1,1.8C179.1,64,179.9,64.5,180.7,64.3L180.7,64.3z"/>
			<path fill="#F59884" d="M182.1,65.8c0.3-0.2,0.7-0.4,1-0.5c-0.2,0.1-0.4,0.2-0.6,0.3c0.8-0.3,1.7-0.6,2.5-0.7
				c-0.2,0-0.4,0.1-0.7,0.1c0.9-0.1,1.7-0.1,2.6,0c-0.2,0-0.4-0.1-0.7-0.1c0.4,0.1,0.8,0.1,1.2,0.2c1.3,0.3,2.8-0.4,3.1-1.7
				s-0.4-2.7-1.7-3.1c-3.1-0.8-6.5-0.4-9.3,1.2c-1.1,0.6-1.6,2.3-0.9,3.4C179.5,66,180.9,66.5,182.1,65.8L182.1,65.8z"/>
			<path fill="#5F0F3F" d="M174.7,35.8c0.7,0,1.4-0.1,2.1-0.1c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8
				s-0.5-0.3-0.8-0.3c-0.7,0-1.4,0.1-2.1,0.1c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8
				C174.1,35.7,174.4,35.8,174.7,35.8L174.7,35.8z"/>
			<path fill="#5F0F3F" d="M191.9,35.9c0.6,0,1.2-0.1,1.9-0.1c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8
				s-0.5-0.3-0.8-0.3c-0.6,0-1.2,0.1-1.9,0.1c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8
				C191.4,35.8,191.6,35.9,191.9,35.9L191.9,35.9z"/>
			<path fill="#5F0F3F" d="M180.4,44.4c-0.1-0.4-0.4-0.8-0.8-0.9c-1.7-0.4-3.4-0.9-5.2-1.3c-0.6-0.1-1.2,0.2-1.4,0.8
				c-0.1,0.5,0.2,1.2,0.9,1.3c0.9,0.2,1.9,0.5,2.9,0.7c-1.1,0.8-2.1,1.6-3.2,2.4c-0.2,0.2-0.4,0.4-0.5,0.7c-0.1,0.3,0,0.6,0.1,0.9
				c0.3,0.5,1,0.8,1.5,0.4c1.7-1.3,3.5-2.6,5.2-3.9C180.3,45.2,180.5,44.9,180.4,44.4z"/>
			<path fill="#5F0F3F" d="M194.9,46.3c-1.3-0.8-2.5-1.6-3.8-2.4c0.7-0.5,1.4-1.1,2.1-1.6c0.5-0.4,0.8-1,0.4-1.5
				c-0.3-0.5-1-0.9-1.5-0.5c-1.2,0.9-2.4,1.8-3.5,2.7c-0.3,0.2-0.6,0.5-0.6,1c0,0.4,0.2,0.8,0.6,1c1.7,1.1,3.4,2.1,5.2,3.2
				c0.5,0.3,1.3,0.1,1.5-0.4C195.6,47.2,195.4,46.6,194.9,46.3z"/>
			<path fill="#5F0F3F" d="M193.4,56.7c-0.6-2.3-2.7-2.8-4.8-2.7c0,0,0,0,0,0c-2.3,0.1-4.6,0.2-6.9,0.3c-2,0.1-4-0.2-5.9,0.7
				c-1.3,0.6-2.4,1.8-2.4,3.3c0,1.3,0.6,2.6,1.3,3.7c2,2.9,5.2,5.1,8.8,5.1c3.5,0,6.8-1.9,8.7-4.8C193.2,60.7,193.9,58.6,193.4,56.7z
				M190.2,61.3c-1.5,2.2-4,3.6-6.6,3.5c-2.7,0-5.2-1.6-6.7-3.8c-0.5-0.7-1.1-1.6-1.2-2.5c-0.1-0.8,0.5-1.3,1.3-1.7
				c1.7-0.7,3.7-0.3,5.5-0.4c2,0,4.1-0.1,6.2-0.2c1.2-0.1,2.6-0.1,2.7,1.5C191.5,59,190.9,60.3,190.2,61.3z"/>
		</g>
	</svg>
</template>

<script>
export default {
	props: {
		face: String,
	},
}
</script>
