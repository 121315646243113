<template functional>
	<!-- carry over any attributes, expecially classes -->
	<div v-bind="data.attrs" :class="[ 'star', data.class, data.staticClass ]">
		<BaseIcon type="starLarge" class="star__icon" />
		<div class="star__label" v-if="props.number">{{ parent.$l10n( props.number ) }}</div>
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		number: Number,
	},
}
</script>
