var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],class:( _obj = {
			'modal': true,
			'is-open': _vm.isOpen,
			'is-mini': _vm.isMini,
			'is-bubble': _vm.isBubble
		}, _obj[ _vm.className ] = true, _obj ),attrs:{"tabindex":"-1"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)},"click":_vm.maybeClose}},[_c('div',{staticClass:"modal__frame"},[(_vm.content)?_c('div',{staticClass:"modal__body",domProps:{"innerHTML":_vm._s(_vm.content)}}):(_vm.body)?_c('div',{staticClass:"modal__body"},[_vm._t("default")],2):_vm._t("default"),_c('BaseButton',{ref:"close",staticClass:"modal__close",attrs:{"name":"close","location":_vm.name,"icon":"close","is-primary":"","is-circular":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$l10n( 'close' )))])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }