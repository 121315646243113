export default function useResponsiveValue( breakpoints, onChange ) {
	breakpoints.map( ( { query, value } ) => {
		const mediaQuery = window.matchMedia( query );

		function handleChange( change ) {
			if ( change.matches ) {
				onChange( value );
			}
		}

		mediaQuery.addEventListener( 'change', handleChange );
		handleChange( mediaQuery );
	} );
}
