const {
	id,
	name,
	config,
	translations,
	main_questions,
	followup_interfaces,
	recovery_questions,
	interrupt_questions,
	demographic_survey,
	levels
} = window.SURVEY_CONFIG || {};

const languages = window.APP_LANGUAGES || {};

export {
	id,
	name,
	config,
	translations,
	main_questions,
	followup_interfaces,
	recovery_questions,
	interrupt_questions,
	demographic_survey,
	levels,
	languages,
};
