<template functional>
	<div :class="[ 'emote', 'emote--' + props.face ]" />
</template>

<script>
export default {
	props: {
		face: String,
	},
}
</script>
