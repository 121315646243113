<template>
	<transition>
		<div
			:class="{
				'modal': true,
				'is-open': isOpen,
				'is-mini': isMini,
				'is-bubble': isBubble,
				[ className ]: true
			}"
			tabindex="-1"
			v-show="isOpen"
			@keyup.esc="close"
			@click="maybeClose"
		>
			<div class="modal__frame">
				<div class="modal__body" v-if="content" v-html="content" />
				<div class="modal__body" v-else-if="body">
					<slot />
				</div>
				<slot v-else />

				<BaseButton
					ref="close"
					name="close"
					:location="name"
					icon="close"
					is-primary
					is-circular
					class="modal__close"
					@click="close"
				>{{ $l10n( 'close' ) }}</BaseButton>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true,
		},
		body: {
			type: Boolean,
			default: true,
		},
		className: {
			type: String,
			default: '',
		},
		content: String,
		isMini: Boolean,
		isBubble: Boolean,
		autoShow: Boolean,
	},
	computed: mapState( {
		isOpen( state ) {
			return state.activeModals.includes( this.name );
		},
	} ),
	methods: mapMutations( {
		close( commit ) {
			this.$emit( 'close' );
			commit( 'hideModal', this.name );
		},
		maybeClose( commit, e ) {
			if ( e.target === this.$el ) {
				this.close( commit );
			}
		},
	} ),
	watch: {
		isOpen( isOpen ) {
			if ( isOpen ) {
				this.$emit( 'show' );
				this.$nextTick( () => this.$el.focus() );
			} else {
				this.$el.blur();
				this.$emit( 'hide' );
			}

			this.$emit( 'toggle', isOpen );
		},
	},
	mounted() {
		if ( this.autoShow ) {
			this.showModal( this.name );
		}
	},
	provide() {
		return {
			parentView: this.name,
		};
	},
}
</script>
