<template>
  <BaseScreen
    :name="id"
    :class="{
      gamescreen: true,
      'with-hands': useHands
    }"
    @toggle="toggleActive"
  >
    <Transition mode="out-in">
      <div
        key="result"
        class="gamescreen__inner gamescreen__result"
        v-if="hasResult"
      >
        <div class="gamescreen__header">
          <h2 class="gamescreen__title" v-content="resultTitle" />

          <div
            class="gamescreen__details"
            v-content="result ? correct_text : incorrect_text"
          />

          <BaseButton
            name="read-more"
            :question="name"
            is-tertiary
            @click="showModal(moreModalId)"
            >{{ $l10n("read-more") }}</BaseButton
          >
        </div>

        <div
          :class="{
            gamescreen__graphic: true,
            'is-correct': result === true,
            'is-incorrect': result === false,
            'is-timeout': result === 'timeout'
          }"
        />

        <BaseButton
          name="next"
          :question="name"
          icon="next"
          is-primary
          is-circular
          class="gamescreen__continue"
          @click="$emit('done')"
          >{{ $l10n("continue") }}</BaseButton
        >
      </div>
      <div key="question" class="gamescreen__inner gamescreen__question" v-else>
        <div class="gamescreen__header">
          <h2 class="gamescreen__title" v-content="title" />
          <div class="gamescreen__details" v-content="text" />
        </div>

        <Component
          class="gamescreen__body"
          :is="uiComponent"
          :is-active="isActive"
          :is-repeat="isRepeat"
          v-bind="control"
          @answer="logAnswer"
        />

        <BaseButton
          v-if="skippable"
          class="gamescreen__skip"
          is-plain
          @click="logAnswer('abstain')"
          >{{ $l10n("skip") }}</BaseButton
        >
      </div>
    </Transition>

    <Portal :to="id">
      <BaseModal class="readmore" v-if="hasResult" :name="moreModalId" isYellow>
        <div class="readmore__header">
          <h2 class="gamescreen__title">{{ resultTitle }}</h2>

          <div
            class="gamescreen__details"
            v-content="result === true ? correct_text : incorrect_text"
          />
        </div>

        <div
          v-if="read_more_text"
          class="readmore__body"
          v-html="read_more_text"
        />

        <BaseButton
          name="correction"
          :question="name"
          is-tertiary
          @click="showModal(correctionModalId)"
          >{{ $l10n("offer-correction") }}</BaseButton
        >
      </BaseModal>
      <BaseModal
        class="correction"
        v-if="hasResult"
        :name="correctionModalId"
        isYellow
      >
        <h2 class="gamescreen__title">{{ $l10n("submit-correction") }}</h2>

        <form class="correction-form" @submit.prevent="submitCorrection">
          <label
            class="correction-form__label"
            :for="`${id}-correction-source`"
            >{{ $l10n("correction-source") }}</label
          >
          <input
            class="correction-form__input"
            type="text"
            :id="`${id}-correction-source`"
            ref="correctionSource"
            required
          />

          <label
            class="correction-form__label"
            :for="`${id}-correction-comments`"
            >{{ $l10n("correction-comments") }}</label
          >
          <textarea
            class="correction-form__input"
            rows="5"
            :id="`${id}-correction-comments`"
            ref="correctionComments"
            maxlength="500"
            required
          />

          <div class="correction-form__actions">
            <BaseButton
              is-secondary
              type="reset"
              @click="hideModal(correctionModalId)"
              >{{ $l10n("cancel") }}</BaseButton
            >
            <BaseButton is-primary is-rounded type="submit">{{
              $l10n("submit")
            }}</BaseButton>
          </div>
        </form>
      </BaseModal>
    </Portal>
  </BaseScreen>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

const NORMAL_QUESTION_TYPES = {
  binary: "ButtonsQuestion",
  quaternary: "ButtonsQuestion",
  slider: "SliderQuestion"
};

const HAND_QUESTION_TYPES = {
  binary: "BinaryDraggableQuestion",
  quaternary: "QuaternaryDraggableQuestion",
  slider: "HandSliderQuestion"
};

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    correct_text: String,
    incorrect_text: String,
    read_more_text: String,
    source_url: String,
    control: {
      type: Object,
      required: true
    },
    answer: {
      type: String,
      default: null
    },
    isRepeat: Boolean,
    affectsScore: {
      type: Boolean,
      default: true
    },
    skippable: Boolean,
    useHands: Boolean
  },
  data() {
    const uiComponent = (this.useHands
      ? HAND_QUESTION_TYPES
      : NORMAL_QUESTION_TYPES)[this.control.type];

    return {
      startTime: Date.now(),
      uiComponent,
      moreModalId: this.id + "-more",
      correctionModalId: this.id + "-correction"
    };
  },
  computed: {
    ...mapState(["currentScreen"]),
    ...mapGetters(["getAnswer"]),

    isActive() {
      return this.currentScreen === this.id;
    },

    hasAnswer() {
      return this.answer !== null;
    },

    result() {
      // Get the stored answer for the question
      const answer = this.getAnswer(this.id);

      return this.getResult(answer);
    },

    resultTitle() {
      if (this.result === true) {
        return this.$l10n("correct");
      } else if (this.result === false) {
        const label = this.$l10n("incorrect");
        let randomLabel = null;
        // TODO: Specific to KION only, change later
        if (
          process.env &&
          process.env.VUE_APP_RANDOM_FALSE_LABEL &&
          process.env.VUE_APP_RANDOM_FALSE_LABEL.toString() == "true"
        ) {
          const sample = arr => arr[Math.floor(Math.random() * arr.length)];
          // if this is true, we find all `incorrect:` prefixed labels and translate them
          const incorrectLabelStrings = Object.keys(
            window.SURVEY_CONFIG.translations
          ).reduce((acc, t) => {
            if (t.includes("incorrect-")) {
              acc = [...acc, this.$l10n(t)];
              return acc;
            }
            return acc;
          }, []);
          randomLabel = sample([label, ...incorrectLabelStrings]);
          // then we pick on of them at random
          this.$log.event({
            event_type: "click",
            target: "incorrect label: " + randomLabel,
            question_name: this.name
          });
        }
        return randomLabel || label;
      }

      return this.$l10n("timed-out");
    },

    hasResult() {
      return this.result !== null;
    }
  },
  watch: {
    isActive() {
      this.startTime = Date.now();
    }
  },
  methods: {
    ...mapMutations(["saveAnswer", "changeScore", "hideModal"]),

    toggleActive(isActive) {
      if (isActive) {
        this.startTime = Date.now();
      }
    },

    getResult(answer) {
      // If timed out, return answer as result
      if (answer === "timeout") {
        return answer;
      }

      // If an answer is required and provided, use the result
      if (this.hasAnswer && answer !== null) {
        // Treat an empty answer as an empty result
        if (answer === "") {
          return answer;
        }
        return answer === this.answer;
      }

      return null;
    },

    logAnswer(answer) {
      const result = this.getResult(answer);
      const isCorrect = result === true;
      const timeSpent = Date.now() - this.startTime;

      this.$emit("answer", result);

      this.saveAnswer({
        question: this.id,
        answer,
        isCorrect
      });

      this.$log.answer({
        question_type: this.isRepeat ? "repeat" : this.control.name,
        question_name: this.name,
        answer_text: answer,
        duration_in_seconds: Math.round(timeSpent / 1000),
        result: this.hasAnswer ? (isCorrect ? "correct" : "incorrect") : ""
      });

      // If no result, just advance
      if (!this.hasResult) {
        this.$emit("done");
      } else if (this.affectsScore) {
        this.changeScore(isCorrect);

        setTimeout(
          () => this.$sounds.play(isCorrect ? "correct" : "incorrect"),
          1500
        );
      }
    },

    submitCorrection() {
      this.$log.correction({
        question_name: this.name,
        source_url: this.$refs.correctionSource.value,
        comments: this.$refs.correctionComments.value
      });

      this.hideModal(this.correctionModalId);
    }
  }
};
</script>
