<template>
	<BaseIcon type="starSmall" :class="{
		'progress-star': true,
		'is-filled': filled,
		'animate': hasChanged,
	}" />
</template>

<script>
export default {
	props: {
		filled: Boolean,
	},
	data() {
		return {
			hasChanged: false,
		};
	},
	watch: {
		filled() {
			this.hasChanged = true;
		},
	},
}
</script>
