<template functional>
	<svg :class="[ 'emote', 'emote--' + props.face ]" viewBox="0 0 72 72">
		<circle class="emote__backfill" fill="transparent" cx="36" cy="36" r="36"/>
		<circle class="emote__fill" :fill="props.fill" stroke="#641744" stroke-width="3" cx="36" cy="36" r="33"/>
		<g class="emote__face" v-if="props.face === 'very-upset'">
			<path fill="#5F0F3F" d="M42.3,41.7c-1-2-2.5-3.7-5-3.4c-2.1,0.3-3.6,1.6-4.9,3.2C35.6,40.6,39.1,40.6,42.3,41.7z"/>
			<path fill="#F59884" d="M24.8,40c2.6,0,2.6-4,0-4S22.2,40,24.8,40L24.8,40z"/>
			<path fill="#F59884" d="M47.5,38.7c2.6,0,2.6-4,0-4S44.9,38.7,47.5,38.7L47.5,38.7z"/>
			<path fill="#F59884" d="M36.3,41.6c0.3-0.2,0.5-0.3,0.7-0.4c0.3-0.1,0.5-0.1,0.9-0.1c0.6,0,1.1,0.2,1.6,0.5c0.2,0.2,0.6,0,0.7-0.2
				c0.1-0.3,0-0.5-0.2-0.7c-1.2-0.9-3-0.9-4.2,0c-0.2,0.2-0.3,0.4-0.2,0.7C35.7,41.6,36.1,41.8,36.3,41.6L36.3,41.6z"/>
			<path fill="#5F0F3F" d="M38.3,17.5c5.4,5.2,10.7,1.7,4.3,0.1c-0.5-0.3-1.4-0.8-2-1.2c-0.1,0-0.1-0.1-0.2-0.1
				C39.4,14.9,37,15.6,38.3,17.5L38.3,17.5z"/>
			<path fill="#5F0F3F" d="M26.6,16.4C26.5,16.7,26.7,16.2,26.6,16.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.2,0.3-0.4,0.7-0.5,1
				c-0.3,0.2-0.8,0.9-1.3,1.1c0,0-0.3,0.2,0,0c-0.5,0.4-0.9,0.6-1.4,0.8c-0.1,0.1-0.2,0.1,0,0c-0.9,0.5-2.8,0.1-2.8,1.5
				c0,0.6,0.5,1.2,1.1,1.1c2.6,0,9.2-4.3,6.3-6.9C27.4,15.5,26.9,15.8,26.6,16.4L26.6,16.4z"/>
			<path fill="#5F0F3F" d="M45.7,31.8c-0.7-0.6-1.5-1.3-2.2-1.9c-0.5-0.4-1.2-1-1.6-1.5c-0.1-0.1-0.1-0.1-0.2-0.2
				c0.4-0.2,0.7-0.4,1.2-0.6c0.9-0.5,1.7-1,2.6-1.5c0.5-0.3,0.7-1,0.4-1.5c-0.4-0.5-1.1-0.7-1.6-0.4c-1,0.6-2,1.1-3,1.7
				c-1,0.6-2.4,1.3-2,2.7c0.1,0.6,0.5,1,0.9,1.3c0.4,0.4,0.9,0.8,1.3,1.2c0.9,0.8,1.7,1.5,2.6,2.3c0.5,0.4,1.1,0.4,1.6,0
				C46.1,33,46.2,32.2,45.7,31.8z M41.4,28.4C41.4,28.4,41.4,28.4,41.4,28.4C41.5,28.4,41.5,28.4,41.4,28.4
				C41.5,28.4,41.5,28.4,41.4,28.4z"/>
			<path fill="#5F0F3F" d="M31.6,26.6c-2.2-0.4-4.3-0.8-6.5-1.2c-0.6-0.1-1.2,0.2-1.4,0.8c-0.1,0.5,0.2,1.2,0.8,1.4
				c1.5,0.3,3.1,0.6,4.6,0.8c-1.2,1.2-2.3,2.5-3.5,3.8c-0.4,0.4-0.5,1.2,0,1.6c0.4,0.4,1.2,0.5,1.6,0c1.6-1.8,3.3-3.5,4.9-5.3
				C32.6,27.9,32.5,26.8,31.6,26.6z"/>
			<path fill="#5F0F3F" d="M46.1,44.3c-0.7-2.1-1.4-4.3-2.9-6c-1.4-1.6-3.4-2.6-5.6-2.5c-4.7,0.3-7.5,4.3-9.4,8.2
				c-0.7,1.1,0.8,2.2,1.6,1.4c0.2,0,0.4,0,0.6-0.2c4.3-2.5,9.8-2.3,14,0.4C45.2,46.1,46.4,45.2,46.1,44.3z M31.6,42.2
				c1.4-2.1,3.1-3.8,5.7-4.1c2.9-0.4,4.5,1.8,5.6,4.2C39.3,40.9,35.2,40.9,31.6,42.2z"/>
		</g>
		<g class="emote__face" v-if="props.face === 'upset'">
			<path fill="#F59884" d="M27.9,48.3c3.9,0,3.9-6,0-6S24,48.3,27.9,48.3L27.9,48.3z"/>
			<path fill="#F59884" d="M49.6,48.5c3.9,0,3.9-6,0-6S45.7,48.5,49.6,48.5L49.6,48.5z"/>
			<path fill="#5F0F3F" d="M49.2,32.5c-0.3-1.3-1.1-2.3-2.3-2.8c-0.7-0.3-1.5-0.3-2.2-0.1c-1.4,0.5-2.2,1.9-2.5,3.3
				c-0.3,1.2-0.4,2.7-0.2,3.9c0,0.2-0.1,0.4,0,0.6c0.4,1.2,1.1,2.2,2.3,2.7c1.2,0.4,2.4,0,3.4-0.7c0.9-0.8,1.4-1.9,1.6-3.1
				C49.5,35.1,49.5,33.7,49.2,32.5z"/>
			<path fill="#5F0F3F" d="M33.3,30c-0.7-0.6-1.7-1-2.7-0.9c-1.6,0.2-2.6,1.5-3,3c-0.3,1.4-0.4,3.1-0.1,4.5c0.6,2.2,2.9,3.9,5.2,2.9
				c2.4-1.1,2.6-4,2.2-6.3C34.7,32,34.2,30.8,33.3,30z"/>
			<path fill="#5F0F3F" d="M32.3,21.5c-0.2,0.3-0.5,0.4-0.7,0.7c0,0,0.2-0.1,0.1,0c-0.3,0.3-0.9,0.5-1.2,0.7c0,0,0.2-0.1,0.1,0
				c-0.4,0.2-0.8,0.2-1.2,0.4h-0.1c-0.2,0,0,0,0.1,0c-1.7-0.5-2.6,1.9-0.7,2.2c1.9,0,6.7-1.5,5.4-4C33.5,21.1,32.8,21,32.3,21.5
				L32.3,21.5z"/>
			<path fill="#5F0F3F" d="M41,22.4c1.4,1.2,6,4,6.9,1.3c0-0.9-0.7-1.2-1.4-1.1c-0.8,0-2.7-0.6-3.7-1.5C42.2,20.2,39.7,20.7,41,22.4
				L41,22.4z"/>
			<path fill="#5F0F3F" d="M37,50.4c0.4-0.8,0.9-1.5,1.5-2.1c0.5-0.5,1.1-0.9,1.9-1c-0.1,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0
				c0,0,0.3,0,0.1,0c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.3,0.1,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0,0,0.2,0.1,0.1,0c0.6,0.5,0.9,1,1,1.8
				c0.1,0.6,0.9,0.9,1.4,0.8c0.6-0.2,0.9-0.8,0.8-1.4c-0.3-2.3-2.5-4-4.8-3.9c-2.5,0.2-4.1,2.2-5.2,4.2c-0.3,0.5-0.1,1.2,0.4,1.5
				C35.9,51.1,36.7,51,37,50.4L37,50.4z"/>
			<path fill="#FFFFFF" d="M30.9,34.1c1.3,0,1.3-2,0-2S29.6,34.1,30.9,34.1L30.9,34.1z"/>
			<path fill="#FFFFFF" d="M45.5,34c1.3,0,1.3-2,0-2S44.2,34,45.5,34L45.5,34z"/>
		</g>
		<g class="emote__face" v-if="props.face === 'neutral'">
			<path fill="#5F0F3F" d="M49.2,32.5c-0.3-1.3-1.1-2.3-2.3-2.8c-0.7-0.3-1.5-0.3-2.2-0.1c-1.4,0.5-2.2,1.9-2.5,3.3
				c-0.3,1.2-0.4,2.7-0.2,3.9c0,0.2-0.1,0.4,0,0.6c0.4,1.2,1.1,2.2,2.3,2.7c1.2,0.4,2.4,0,3.4-0.7c0.9-0.8,1.4-1.9,1.6-3.1
				C49.5,35.1,49.5,33.7,49.2,32.5z"/>
			<path fill="#5F0F3F" d="M34.9,33.2c-0.2-1.2-0.7-2.4-1.6-3.2c-0.7-0.6-1.7-1-2.7-0.9c-1.6,0.2-2.6,1.5-3,3
				c-0.3,1.4-0.4,3.1-0.1,4.5c0.6,2.2,2.9,3.9,5.2,2.9C35.1,38.4,35.3,35.5,34.9,33.2z"/>
			<path fill="#FFFFFF" d="M45.8,33.7c0.6,0,0.6-1,0-1C45.1,32.7,45.1,33.7,45.8,33.7L45.8,33.7z"/>
			<path fill="#FFFFFF" d="M30.7,33.4c0.6,0,0.6-1,0-1S30.1,33.4,30.7,33.4L30.7,33.4z"/>
			<path fill="#F59884" d="M27.4,48.4c3.9,0,3.9-6,0-6S23.5,48.4,27.4,48.4L27.4,48.4z"/>
			<path fill="#F59884" d="M48.9,48.2c3.9,0,3.9-6,0-6S45,48.2,48.9,48.2L48.9,48.2z"/>
			<path fill="#5F0F3F" d="M28.4,25.8c0.7,0,1.4-0.1,2.1-0.1c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8
				s-0.5-0.3-0.8-0.3c-0.7,0-1.4,0.1-2.1,0.1c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8C27.8,25.7,28.1,25.8,28.4,25.8
				L28.4,25.8z"/>
			<path fill="#5F0F3F" d="M45.6,25.9c0.6,0,1.2-0.1,1.9-0.1c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8
				s-0.5-0.3-0.8-0.3c-0.6,0-1.2,0.1-1.9,0.1c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8C45.1,25.8,45.3,25.9,45.6,25.9
				L45.6,25.9z"/>
			<path fill="#5F0F3F" d="M37.7,49.6c1.3-0.1,2.6-0.1,4-0.2c0.6,0,1.2-0.5,1.1-1.1c0-0.6-0.5-1.2-1.1-1.1c-1.3,0.1-2.6,0.1-4,0.2
				c-0.6,0-1.2,0.5-1.1,1.1C36.6,49,37,49.6,37.7,49.6L37.7,49.6z"/>
				</g>
				<g class="emote__face" v-if="props.face === 'happy'">
					<path fill="#5F0F3F" d="M49.4,32.5c-0.3-1.4-1.2-2.5-2.6-3c-0.7-0.3-1.6-0.4-2.3-0.1c-1.5,0.5-2.3,2-2.7,3.5
				c-0.4,1.5-0.5,3.2-0.1,4.6c0.4,1.3,1.3,2.4,2.5,2.9c1.3,0.5,2.6,0,3.7-0.8c0.9-0.8,1.5-2,1.6-3.2C49.7,35.1,49.7,33.7,49.4,32.5z"
				/>
			<path fill="#5F0F3F" d="M35.2,33.1c-0.2-1.2-0.7-2.5-1.7-3.3c-0.8-0.7-1.8-1.1-2.9-1c-1.7,0.2-2.7,1.7-3.2,3.2
				c-0.4,1.5-0.5,3.2-0.2,4.7c0.7,2.4,3.2,4.2,5.7,3S35.6,35.5,35.2,33.1z"/>
			<path fill="#5F0F3F" d="M45.2,44.9c-1.7-1.2-3.7-0.6-5.5-0.2h-0.2c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.7,0c-0.6,0-1.1,0-1.7,0
				c-1.2,0-2.4,0.2-3.4,0.9c-1.3,0.9-2,2.4-1.7,4c0.2,1.5,1.1,2.8,2.1,3.9c2,2,5.3,3.2,8.2,2.3c2.8-0.9,5-3.4,5.2-6.4
				C47.3,47.7,46.6,45.9,45.2,44.9z"/>
			<path fill="#63CD9A" d="M45.6,32L45.6,32L45.6,32C45.5,32,45.5,32,45.6,32C45.5,32,45.5,32,45.6,32L45.6,32L45.6,32z"/>
			<path fill="#63CD9A" d="M45.3,32.1L45.3,32.1C45.2,32.2,45.2,32.2,45.3,32.1L45.3,32.1z"/>
			<path fill="#63CD9A" d="M44.4,34.5L44.4,34.5C44.4,34.4,44.4,34.3,44.4,34.5z"/>
			<path fill="#63CD9A" d="M45.6,32L45.6,32C45.7,32,45.7,32,45.6,32z"/>
			<path fill="#63CD9A" d="M45.4,32L45.4,32C45.4,32.1,45.4,32.1,45.4,32C45.4,32.1,45.4,32.1,45.4,32C45.4,32.1,45.4,32.1,45.4,32z"
				/>
			<path fill="#5F0F3F" d="M45.4,32.1L45.4,32.1c-0.1,0-0.1,0.1-0.1,0.1C45.3,32.2,45.3,32.1,45.4,32.1z"/>
			<path fill="#5F0F3F" d="M45.7,32C45.6,32,45.6,32,45.7,32C45.6,32,45.6,32,45.7,32c-0.1,0-0.1,0-0.1,0S45.6,32,45.7,32z"/>
			<path fill="#5F0F3F" d="M29.9,33.8C29.9,33.9,29.9,34.1,29.9,33.8L29.9,33.8z"/>
			<path fill="#FFFFFF" d="M30.8,34.5c1.9,0,1.9-3,0-3C28.9,31.5,28.9,34.5,30.8,34.5L30.8,34.5z"/>
			<path fill="#FFFFFF" d="M45.2,34.6c1.9,0,1.9-3,0-3S43.3,34.6,45.2,34.6L45.2,34.6z"/>
			<path fill="#F59884" d="M50.7,46.4c3.9,0,3.9-6,0-6S46.8,46.4,50.7,46.4L50.7,46.4z"/>
			<path fill="#F59884" d="M25.6,46.8c3.9,0,3.9-6,0-6S21.7,46.8,25.6,46.8L25.6,46.8z"/>
			<path fill="#F59884" d="M37.3,53.2c0.9-0.3,2-0.6,2.9-0.6c1-0.1,2.1,0,2.9,0.1c0.5,0.1,1.1-0.1,1.2-0.7c0.1-0.5-0.1-1.1-0.7-1.2
				c-2.3-0.4-4.6-0.3-6.8,0.5c-0.5,0.2-0.9,0.7-0.7,1.2C36.2,53,36.7,53.4,37.3,53.2L37.3,53.2z"/>
			<path fill="#5F0F3F" d="M31.5,23.6c-0.3-0.2-0.6-0.4-1-0.4c-0.7,0-1.4,0.1-2.1,0.1c-0.4,0-0.7,0.1-1,0.4c-0.2,0.2-0.4,0.6-0.4,1
				c0,0.4,0.1,0.7,0.4,1c0.2,0.2,0.6,0.4,1,0.4c0.7,0,1.4-0.1,2.1-0.1c0.4,0,0.7-0.1,1-0.4c0.2-0.2,0.4-0.6,0.4-1
				C31.9,24.2,31.8,23.9,31.5,23.6z"/>
			<path fill="#5F0F3F" d="M48.5,23.7c-0.3-0.2-0.6-0.4-1-0.4c-0.6,0-1.2,0.1-1.9,0.1c-0.4,0-0.7,0.1-1,0.4c-0.1,0.1-0.2,0.3-0.3,0.5
				c-0.1,0.2-0.1,0.4-0.1,0.5c0,0.4,0.1,0.7,0.4,1c0.3,0.2,0.7,0.4,1,0.4c0.6,0,1.2-0.1,1.9-0.1c0.4,0,0.7-0.1,1-0.4
				c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.5C48.9,24.3,48.8,24,48.5,23.7z"/>
		</g>
		<g class="emote__face" v-if="props.face === 'very-happy'">
			<path fill="#5F0F3F" d="M35.2,54.2c0.6,0.2,1.3,0.3,2,0.3c2.5,0,5-1.3,6.4-3.4c0.7-1,1.3-2.2,1.2-3.4c-0.1-1.3-1.4-1.2-2.4-1.2
				c-2.1,0.1-4.2,0.2-6.2,0.3c-0.9,0-1.9,0-2.8,0c-0.9,0-1.7,0-2.6,0.3c-0.4,0.1-0.8,0.5-0.9,0.6c-0.2,0.2-0.1,0.2-0.2,0.4
				c0-0.2,0,0.3,0,0.3c0,0.2,0.2,0.7,0.3,1c0.2,0.5,0.5,0.9,0.8,1.3C31.8,52.4,33.4,53.6,35.2,54.2"/>
			<path fill="#F59884" d="M20.7,45.7c3.2,0,3.2-5,0-5C17.4,40.7,17.4,45.7,20.7,45.7L20.7,45.7z"/>
			<path fill="#F59884" d="M51.4,44.7c3.2,0,3.2-5,0-5S48.2,44.7,51.4,44.7L51.4,44.7z"/>
			<path fill="#5F0F3F" d="M34.4,54.3c0.3-0.1,0.6-0.2,0.9-0.2c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.6-0.1,0.3,0c0.6-0.1,1.2-0.1,1.8-0.1
				c0.3,0,0.6,0,0.9,0c0.2,0,0.3,0,0.5,0c0.4,0-0.3-0.1,0.1,0c0.6,0.1,1.2,0.3,1.9,0.4c0.7,0.2,1.7-0.3,1.8-1c0.2-0.8-0.2-1.6-1-1.8
				c-2.7-0.8-5.6-0.9-8.3-0.1c-0.8,0.2-1.3,1.1-1,1.8C32.8,54,33.6,54.5,34.4,54.3L34.4,54.3z"/>
			<path fill="#F59884" d="M35.8,55.8c0.3-0.2,0.7-0.4,1-0.5c-0.2,0.1-0.4,0.2-0.6,0.3c0.8-0.3,1.7-0.6,2.5-0.7
				c-0.2,0-0.4,0.1-0.7,0.1c0.9-0.1,1.7-0.1,2.6,0c-0.2,0-0.4-0.1-0.7-0.1c0.4,0.1,0.8,0.1,1.2,0.2c1.3,0.3,2.8-0.4,3.1-1.7
				s-0.4-2.7-1.7-3.1c-3.1-0.8-6.5-0.4-9.3,1.2c-1.1,0.6-1.6,2.3-0.9,3.4C33.2,56,34.6,56.5,35.8,55.8L35.8,55.8z"/>
			<path fill="#5F0F3F" d="M28.4,25.8c0.7,0,1.4-0.1,2.1-0.1c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8
				s-0.5-0.3-0.8-0.3c-0.7,0-1.4,0.1-2.1,0.1c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8C27.8,25.7,28.1,25.8,28.4,25.8
				L28.4,25.8z"/>
			<path fill="#5F0F3F" d="M45.6,25.9c0.6,0,1.2-0.1,1.9-0.1c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8
				s-0.5-0.3-0.8-0.3c-0.6,0-1.2,0.1-1.9,0.1c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8C45.1,25.8,45.3,25.9,45.6,25.9
				L45.6,25.9z"/>
			<path fill="#5F0F3F" d="M34.1,34.4c-0.1-0.4-0.4-0.8-0.8-0.9c-1.7-0.4-3.4-0.9-5.2-1.3c-0.6-0.1-1.2,0.2-1.4,0.8
				c-0.1,0.5,0.2,1.2,0.9,1.3c0.9,0.2,1.9,0.5,2.9,0.7c-1.1,0.8-2.1,1.6-3.2,2.4c-0.2,0.2-0.4,0.4-0.5,0.7c-0.1,0.3,0,0.6,0.1,0.9
				c0.3,0.5,1,0.8,1.5,0.4c1.7-1.3,3.5-2.6,5.2-3.9C34,35.2,34.2,34.9,34.1,34.4z"/>
			<path fill="#5F0F3F" d="M48.6,36.3c-1.3-0.8-2.5-1.6-3.8-2.4c0.7-0.5,1.4-1.1,2.1-1.6c0.5-0.4,0.8-1,0.4-1.5
				c-0.3-0.5-1-0.9-1.5-0.5c-1.2,0.9-2.4,1.8-3.5,2.7c-0.3,0.2-0.6,0.5-0.6,1c0,0.4,0.2,0.8,0.6,1c1.7,1.1,3.4,2.1,5.2,3.2
				c0.5,0.3,1.3,0.1,1.5-0.4C49.3,37.2,49.1,36.6,48.6,36.3z"/>
			<path fill="#5F0F3F" d="M47.1,46.7c-0.6-2.3-2.7-2.8-4.8-2.7c0,0,0,0,0,0c-2.3,0.1-4.6,0.2-6.9,0.3c-2,0.1-4-0.2-5.9,0.7
				c-1.3,0.6-2.4,1.8-2.4,3.3c0,1.3,0.6,2.6,1.3,3.7c2,2.9,5.2,5.1,8.8,5.1c3.5,0,6.8-1.9,8.7-4.8C46.9,50.7,47.6,48.6,47.1,46.7z
				M43.9,51.3c-1.5,2.2-4,3.6-6.6,3.5c-2.7,0-5.2-1.6-6.7-3.8c-0.5-0.7-1.1-1.6-1.2-2.5c-0.1-0.8,0.5-1.3,1.3-1.7
				c1.7-0.7,3.7-0.3,5.5-0.4c2,0,4.1-0.1,6.2-0.2c1.2-0.1,2.6-0.1,2.7,1.5C45.2,49,44.6,50.3,43.9,51.3z"/>
		</g>
	</svg>
</template>

<script>
export default {
	props: {
		face: String,
	},
}
</script>
